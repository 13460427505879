import axios from "axios";
import { createContext, useState, useContext } from "react";
import LoginContext from "../login/LoginContext";
const SuggestionContext = createContext();

export const SuggestionProvider = ({ children }) => {
  const { user } = useContext(LoginContext);

  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [sugerencias, setSugerencias] = useState([]);
  const [sugerencia, setSugerencia] = useState(null);
  const [suggestion, setSuggestion] = useState({});

  /*   useEffect(() => {
    fetchSuggestions();
  }, []); */

  // Fetch suggestions from DB
  const getSuggestions = async () => {
    setIsLoading(true);
    const response = await fetch("/api/suggestions", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = await response.json();

    setSuggestions(data.suggestions);
    setIsLoading(false);
  };

  // Fetch sugerencias from DB
  const getAllSugerencias = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/sugerencias?aid=${asadaId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.data;
    const sugerencias = data.sugerencias;
    setSugerencias(sugerencias);
    setIsLoading(false);
  };

  // Fetch sugerencias from DB
  const getAllSugerenciasPrivate = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/sugerencias?aid=${asadaId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.data;
    const sugerencias = data.sugerencias;
    setSugerencias(sugerencias);
    setIsLoading(false);
  };

  // Get suggestion by ID
  const getSuggestion = async (id) => {
    setIsLoading(true);
    const response = await fetch(`/api/suggestions/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = await response.json();
    setSuggestion(data.suggestion[0]);
    setIsLoading(false);
  };

  // Get single sugerencia by ID
  const getSingleSugerencia = async (sugerenciaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/sugerencias/${sugerenciaId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.data;
    const sugerencia = data.sugerencia;
    setSugerencia(sugerencia);
    setIsLoading(false);
  };

  // Add suggestion to DB
  const addSuggestion = async (newSuggestion) => {
    try {
      setIsLoading(true);
      const response = await fetch("/api/suggestions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newSuggestion),
      });

      const data = await response.json();

      setSuggestions([data, ...suggestions]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      throw new Error("Error al agregar sugerencia");
    }
  };

  // Create sugerencia
  const createSugerencia = async (newSugerencia) => {
    console.log(newSugerencia);
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/sugerencias?aid=${process.env.REACT_APP_ASADA_ID}`,
        newSugerencia,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.data;
      const sugerencia = data.sugerencia;

      setSuggestions([sugerencia, ...sugerencias]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error("Error al agregar sugerencia");
    }
  };

  // Update suggestion to DB
  const updateSuggestion = async (suggestion) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `/api/suggestions/${suggestion.idsuggestions}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify(suggestion),
        }
      );

      const data = await response.json();

      setIsLoading(false);

      return data;
    } catch (error) {
      console.log(error);
      throw new Error(`Error al actualizar sugerencia: ${suggestion}`);
    }
  };

  // Update sugerencia to DB
  const updateSugerencia = async (sugerencia) => {
    const { id: sugerenciaId } = sugerencia;
    try {
      setIsLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/sugerencias/${sugerenciaId}`,
        sugerencia,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.data;
      const sugerenciaUpdated = data.sugerencia;
      setSugerencia(sugerenciaUpdated);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error(`Error al actualizar sugerencia: ${suggestion}`);
    }
  };

  return (
    <SuggestionContext.Provider
      value={{
        suggestions,
        addSuggestion,
        isLoading,
        getSuggestions,
        getSuggestion,
        suggestion,
        setSuggestion,
        updateSuggestion,
        getAllSugerencias,
        sugerencias,
        sugerencia,
        createSugerencia,
        getSingleSugerencia,
        setSugerencia,
        updateSugerencia,
        getAllSugerenciasPrivate,
      }}
    >
      {children}
    </SuggestionContext.Provider>
  );
};

export default SuggestionContext;
