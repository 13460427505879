import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SuggestionContext from "../../context/suggestions/SuggestionContext";
import Spinner from "../shared/Spinner";
import "moment/locale/es";
import { toast } from "react-toastify";
import Moment from "react-moment";
import Modal from "../shared/Modal";

function SuggestionItem() {
  const { isLoading, sugerencia, getSingleSugerencia, updateSugerencia } =
    useContext(SuggestionContext);

  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const [estado, setEstado] = useState(true);
  const [btnDisable, setBtnDisable] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const onChange = (e) => {
    setEstado(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const sugerenciaObj = {
      id,
      is_pendiente: estado,
    };

    await updateSugerencia(sugerenciaObj);

    toast.info(`Se ha actualizado el estado de la sugerencia`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    if (sugerencia !== null) {
      estado === sugerencia.is_pendiente
        ? setBtnDisable(true)
        : setBtnDisable(false);
    }
  }, [estado, sugerencia]);

  useEffect(() => {
    const fetchSugerencia = async () => {
      await getSingleSugerencia(id);
    };
    fetchSugerencia();
  }, []);

  return (
    <div className='rounded-lg shadow-lg card bg-base-100'>
      <div className='card-body'>
        <h2 className='text-2xl my-4 font-bold card-title'>
          <button
            onClick={() => navigate(-1)}
            className='btn btn-outline btn-accent btn-sm hover:text-white'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 '
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z'
                clipRule='evenodd'
              />
            </svg>
          </button>
          Detalle de comentario
        </h2>

        {sugerencia ? (
          <div className='text-lg flex justify-center'>
            <form className='w-full max-w-lg' onSubmit={onSubmit}>
              <div className='form-control text-sm mb-5'>
                <p>
                  Fecha de emisión:
                  <span>
                    &nbsp;
                    <Moment locale='es' fromNow format='DD/MM/YYYY hh:mm:ss a'>
                      {sugerencia.createdAt}
                    </Moment>
                    &nbsp;|&nbsp;
                    <Moment locale='es' fromNow>
                      {sugerencia.createdAt}
                    </Moment>
                  </span>
                </p>
                <p>
                  Última actualización:
                  <span>
                    &nbsp;
                    <Moment locale='es' fromNow format='DD/MM/YYYY hh:mm:ss a'>
                      {sugerencia.updatedAt}
                    </Moment>
                    &nbsp;|&nbsp;
                    <Moment locale='es' fromNow>
                      {sugerencia.updatedAt}
                    </Moment>
                  </span>
                </p>
              </div>
              <div className='w-full px-3'>
                <label
                  className='block tracking-wide0 text-xs font-bold mb-2'
                  htmlFor='grid-name'
                >
                  Nombre
                </label>
                <input
                  disabled
                  className='appearance-none block w-full cursor-not-allowed bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                  id='grid-nombre'
                  type='text'
                  required
                  name='nombre'
                  value={sugerencia.nombre}
                />
              </div>
              <div className='w-full px-3'>
                <label
                  className='block tracking-wide text-xs font-bold mb-2'
                  htmlFor='grid-last_name'
                >
                  Apellidos
                </label>
                <input
                  disabled
                  className='appearance-none block w-full cursor-not-allowed bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                  id='grid-apellidos'
                  type='text'
                  required
                  name='apellidos'
                  value={sugerencia.apellidos}
                />
              </div>
              <div className='w-full px-3'>
                <label
                  className='block tracking-wide text-xs font-bold mb-2'
                  htmlFor='grid-email'
                >
                  Correo electrónico
                </label>
                <input
                  disabled
                  className='appearance-none block w-full cursor-not-allowed bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                  id='grid-correo'
                  type='email'
                  required
                  name='correo'
                  value={sugerencia.correo}
                />
              </div>
              <div className='w-full px-3'>
                <label
                  className='block tracking-wide text-xs font-bold mb-2'
                  htmlFor='grid-subject'
                >
                  Asunto
                </label>
                <input
                  disabled
                  className='appearance-none block w-full cursor-not-allowed bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                  id='grid-asunto'
                  type='text'
                  required
                  name='asunto'
                  value={sugerencia.asunto}
                />
              </div>
              <div className='w-full px-3'>
                <label
                  className='block tracking-wide text-xs font-bold mb-2'
                  htmlFor='grid-message'
                >
                  Asunto
                </label>
                <textarea
                  disabled
                  className='appearance-none block w-full cursor-not-allowed bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                  id='grid-message'
                  required
                  name='mensaje'
                  value={sugerencia.mensaje}
                />
              </div>
              <div className='w-full px-3'>
                <label
                  className='block tracking-wide  text-xs font-bold mb-2'
                  htmlFor='grid-message'
                >
                  {`Estado actual: ${
                    sugerencia.is_pendiente === true ? "Pendiente" : "Revisado"
                  }`}
                </label>

                <div className='form-control'>
                  <select
                    className='select select-accent w-full max-w-xs'
                    onChange={onChange}
                    value={sugerencia.estado}
                  >
                    <option value={true}>Pendiente</option>
                    <option value={false}>Revisado</option>
                  </select>
                </div>
              </div>
              <div className='divider'></div>

              <div className='card-actions justify-start'>
                <button
                  type='submit'
                  className={`btn btn-success hover:bg-green-700 capitalize ${
                    isLoading ? "loading" : ""
                  } `}
                  defaultValue={false}
                  disabled={btnDisable}
                >
                  {!isLoading ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                    >
                      <path d='M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z' />
                    </svg>
                  ) : (
                    <></>
                  )}
                  &nbsp;Guardar cambios
                </button>
                <button
                  type='button'
                  className='btn btn-ghost ml-2 justify-self-end'
                  onClick={openModal}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z'
                    />
                  </svg>
                </button>
              </div>
            </form>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <div>
          <p className='font-light text-lg'>Consideraciones</p>
          <ul className='list-disc'>
            <li className='font-light text-sm my-1'>
              El botón 'Guardar Cambios' se activa cuando el valor del campo
              'Estado actual' cambia.
            </li>
            <li className='font-light text-sm my-1'>
              El campo 'Estado actual' es el único que se puede editar en una
              sugerencia/comentario.
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default SuggestionItem;
