import { useContext, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import LoginContext from "../../context/login/LoginContext";
import AsadaContext from "../../context/asada/asadaContext";

import UserLogin from "../users/UserLogin";
import logo from "../../assets/logo.jpg";

function AdminLayout() {
    const { user, logout } = useContext(LoginContext);
    const { asada } = useContext(AsadaContext);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    if (!user) {
        return <UserLogin />;
    }

    const handleClick = () => {
        setIsOpen(!isOpen);
    };
    const handleLogout = () => {
        logout();
        return navigate("/admin");
    };

    return (
        <div className="relative min-h-screen md:flex">
            {/* Mobile Menu Bar */}
            <div className="bg-slate-700 text-blue-100 flex justify-between md:hidden">
                {/* logo */}
                {user && user.role === "admin" ? (
                    <NavLink
                        to="/admin"
                        className="block p-4 text-white font-bold"
                    >
                        Gestor de Contenido | AsadasCR
                    </NavLink>
                ) : (
                    <NavLink
                        to="/admin"
                        className="block p-4 text-white font-bold"
                    >
                        Gestor de Contenido |{" "}
                        {!asada ? asada.nombre : <p>Sin nombre</p>}
                    </NavLink>
                )}

                {/* logo menu button */}
                <button
                    className="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
                    onClick={handleClick}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                </button>
            </div>

            {/* sidebar */}
            <aside
                className={`sidebar  text-blue-100 w-64  space-y-6 px-2 py-7 absolute md:relative md:translate-x-0 inset-y-0 left-0 transform z-20 ${
                    isOpen ? `-translate-x-full` : ``
                } transition duration-200 ease-in-out`}
            >
                {/* logo */}
                <NavLink
                    to="/admin"
                    className="text-white flex items-center space-x-2 px-4"
                >
                    <div className="h-10 w-10">
                        <img
                            src="https://asadascr.com/logo.jpg"
                            alt="CISA logo"
                            className="h-full w-full object-cover rounded-md"
                        />
                    </div>
                    {user && user.role === "admin" ? (
                        <span className="text-lg font-extrabold">MOGEAS</span>
                    ) : (
                        <span className="text-lg font-extrabold">
                            {asada ? asada.nombre : "Sin nombre"}
                        </span>
                    )}
                </NavLink>

                <div>
                    <p className="py-2.5 px-4">
                        {user ? user.name : "Cargando usuario..."}
                    </p>
                    <NavLink
                        to="/admin"
                        type="button"
                        className="flex py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                        onClick={handleLogout}
                    >
                        {" "}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                            />
                        </svg>
                        &nbsp; Cerrar sesión
                    </NavLink>
                </div>
                <hr />
                {/* nav */}
                <nav>
                    {user && user.role === "admin" ? (
                        /* ADMINISTRADOR DE CISA */
                        <>
                            <NavLink
                                to="asadas"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Asadas
                            </NavLink>
                            <NavLink
                                to="bancos-procesadores"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Bancos Procesadores
                            </NavLink>
                            <NavLink
                                to="sugerencias"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Buzón de Sugerencias
                            </NavLink>
                            <NavLink
                                to="contactos"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Contactos
                            </NavLink>
                            <NavLink
                                to="documentacion"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Documentación
                            </NavLink>
                            <NavLink
                                to="integrantes"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Junta Directiva
                            </NavLink>
                            <NavLink
                                to="solicitudes"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Solicitudes de Empleo
                            </NavLink>
                            <NavLink
                                to="tanques"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Tanques de Almacenamiento
                            </NavLink>
                            <NavLink
                                to="proyectos"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Proyectos
                            </NavLink>
                            <NavLink
                                to="publicaciones"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Publicaciones
                            </NavLink>
                            <NavLink
                                to="pozosadmin"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Pozos
                            </NavLink>
                            <NavLink
                                to="gradiente"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Quiebragradientes
                            </NavLink>
                        </>
                    ) : (
                        /* ADMINISTRADOR DE ASADA */
                        <>
                            <NavLink
                                to="dashboard/general"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                General
                            </NavLink>
                            <NavLink
                                to="suggestions"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Buzón de Sugerencias
                            </NavLink>

                            <NavLink
                                to="docs"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Documentación
                            </NavLink>
                            <NavLink
                                to="jobs"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Solicitudes de Empleo
                            </NavLink>
                            <NavLink
                                to="tanks"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Tanques de Almacenamiento
                            </NavLink>
                            <NavLink
                                to="projects"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Proyectos
                            </NavLink>
                            <NavLink
                                to="posts"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Publicaciones
                            </NavLink>
                            <NavLink
                                to="pozos"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Pozos
                            </NavLink>
                            <NavLink
                                to="gradientes"
                                className="block py-2.5 px-4 transition duration-200 rounded hover:bg-accent hover:text-white focus:bg-accent-focus focus:text-white focus:text-bold"
                            >
                                Quiebragradientes
                            </NavLink>
                        </>
                    )}
                </nav>
            </aside>

            {/* content */}
            <div className="flex-1 p-10 text-2xl font-bold bg-gray-900">
                <Outlet />
            </div>
        </div>
    );
}

export default AdminLayout;
