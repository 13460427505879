import { useState, useContext, useEffect } from "react";
import AsadaContext from "../../context/asada/asadaContext";
import Modal from "../shared/Modal";
import LoginContext from "../../context/login/LoginContext";
import { toast } from "react-toastify";
import DeleteImgButton from "../shared/DeleteImgButton";

const MAX_SIZE_IN_BYTES = 3000 * 1024; // 3 MB

function ImagenesAsada() {
  const { isLoading, getAllFotosEstaticas, createAsadaImagen, deleteAsadaImagen, imagenes } = useContext(AsadaContext);
  const { user } = useContext(LoginContext);

  const [isPrincipalBtnDisabled, setIsPrincipalBtnDisabled] = useState(true);
  const [isExtensionBtnDisabled, setIsExtensionBtnDisabled] = useState(true);
  const [isUsuariosBtnDisabled, setIsUsuariosBtnDisabled] = useState(true);
  const [isTanqueCardBtnDisabled, setIsTanqueCardBtnDisabled] = useState(true);
  const [isGradienteCardBtnDisabled, setIsGradienteCardBtnDisabled] = useState(true);
  const [isTanqueBtnDisabled, setIsTanqueBtnDisabled] = useState(true);
  const [isGradienteBtnDisabled, setIsGradienteBtnDisabled] = useState(true);
  const [isServiciosBtnDisabled, setIsServiciosBtnDisabled] = useState(true);
  const [isProyectosBtnDisabled, setIsProyectosBtnDisabled] = useState(true);
  const [isNosotrosBtnDisabled, setIsNosotrosBtnDisabled] = useState(true);
  const [isDocumentacionBtnDisabled, setIsDocumentacionBtnDisabled] = useState(true);
  const [isPozoBtnDisabled, setIsPozoBtnDisabled] = useState(true);

  const [principalImgFilename, setPrincipalImgFilename] = useState("");
  const [extensionImgFilename, setExtensionImgFilename] = useState("");
  const [usuariosImgFilename, setUsuariosImgFilename] = useState("");
  const [tanqueCardImgFilename, setTanqueCardImgFilename] = useState("");
  const [gradienteCardImgFilename, setGradienteCardImgFilename] = useState("");
  const [tankImgFilename, setTankImgFilename] = useState("");
  const [gradienteImgFilename, setGradienteImgFilename] = useState("");
  const [serviciosImgFilename, setServiciosImgFilename] = useState("");
  const [proyectosImgFilename, setProyectosImgFilename] = useState("");
  const [nosotrosImgFilename, setNosotrosImgFilename] = useState("");
  const [documentacionImgFilename, setDocumentacionImgFilename] = useState("");
  const [pozoImgFilename, setPozoImgFilename] = useState("");

  const [tipo, setTipo] = useState("");
  const [nombreImagen, setNombreImagen] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const handleImageSubmit = async (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.set("type", tipo);
    fd.append("foto", nombreImagen[0]);

    // Verificar el tamaño de la imagen
    const file = nombreImagen[0];
    if (file && file.size > MAX_SIZE_IN_BYTES) {
      toast.error(`La imagen es demasiado grande. Tamaño máximo permitido: 3 MB`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    await createAsadaImagen(fd, user.asadaId);
    await getAllFotosEstaticas(user.asadaId);
  };

  const handleImageDelete = async (imagen) => {
    const status = await deleteAsadaImagen(imagen?.id)
    if(status === 200) {      
      toast.info(`Imagen borrada`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(`Ha ocurrido un error al borrar la imagen`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const resetButtonsDefaultState = () => {
    setIsPrincipalBtnDisabled(true);
    setIsExtensionBtnDisabled(true);
    setIsUsuariosBtnDisabled(true);
    setIsTanqueCardBtnDisabled(true);
    setIsGradienteCardBtnDisabled(true);
    setIsTanqueBtnDisabled(true);
    setIsGradienteBtnDisabled(true);
    setIsServiciosBtnDisabled(true);
    setIsProyectosBtnDisabled(true);
    setIsNosotrosBtnDisabled(true);
    setIsDocumentacionBtnDisabled(true);
    setIsPozoBtnDisabled(true);
  };

  useEffect(() => {
    const fetchImagenes = async () => await getAllFotosEstaticas(user.asadaId);
    fetchImagenes();
  }, []);

  useEffect(() => {
    if (imagenes.length > 0) {
      const principalImg = imagenes.filter((item) => item.isPrincipalImg === true)[0];
      const extensionImg = imagenes.filter((item) => item.isExtensionImg === true)[0];
      const usuariosImg = imagenes.filter((item) => item.isUsuariosImg === true)[0];
      const tanqueCardImg = imagenes.filter((item) => item.isTanqueCardImg === true)[0];
      const gradienteCardImg = imagenes.filter((item) => item.isGradienteCardImg === true)[0];
      const tanqueImg = imagenes.filter((item) => item.isTanqueImg === true)[0];
      const gradienteImg = imagenes.filter((item) => item.isGradienteImg === true)[0];
      const serviciosImg = imagenes.filter((item) => item.isServiciosImg === true)[0];
      const proyectosImg = imagenes.filter((item) => item.isProyectosImg === true)[0];
      const nosotrosImg = imagenes.filter((item) => item.isNosotrosImg === true)[0];
      const documentacionImg = imagenes.filter((item) => item.isDocumentacionImg === true)[0];
      const pozoImg = imagenes.filter((item) => item.isPozoImg === true)[0];

      if (principalImg !== undefined) setPrincipalImgFilename(principalImg.path);
      if (extensionImg !== undefined) setExtensionImgFilename(extensionImg.path);
      if (usuariosImg !== undefined) setUsuariosImgFilename(usuariosImg.path);
      if (tanqueCardImg !== undefined) setTanqueCardImgFilename(tanqueCardImg.path);
      if (gradienteCardImg !== undefined) setGradienteCardImgFilename(gradienteCardImg.path);
      if (tanqueImg !== undefined) setTankImgFilename(tanqueImg.path);
      if (gradienteImg !== undefined) setGradienteImgFilename(gradienteImg.path);
      if (serviciosImg !== undefined) setServiciosImgFilename(serviciosImg.path);
      if (proyectosImg !== undefined) setProyectosImgFilename(proyectosImg.path);
      if (nosotrosImg !== undefined) setNosotrosImgFilename(nosotrosImg.path);
      if (documentacionImg !== undefined) setDocumentacionImgFilename(documentacionImg.path);
      if (pozoImg !== undefined) setPozoImgFilename(pozoImg.path);
    }
  }, [imagenes]);

  return (
    <>
      <h2>
        Página de Inicio{" "}
        <button type="button" className="btn btn-ghost ml-2 justify-self-end" onClick={openModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
            />
          </svg>
        </button>
      </h2>
      <p className="text-base font-light">Estas imágenes aparecerán en la página pública de Inicio de la ASADA</p>
      <div className="flex flex-wrap gap-2">
        <div className="text-lg mt-5">
          <form className="w-full max-w-lg" onSubmit={handleImageSubmit}>
            <div className="px-3 ">
              <label className="block tracking-wide text-xs font-bold mb-2 " htmlFor="grid-principal">
                Imagen Principal <small>(Recomendada: 1920x980px)</small>
              </label>
              <div className="mb-3 h-[180px] relative">
              {principalImgFilename !== "" ? (
                <>
                  <img
                    src={
                      process.env.REACT_APP_NODE_ENV === "production"
                        ? `${process.env.REACT_APP_BASE_URL}/uploads/${principalImgFilename.split("/")[4]}`
                        : `http://localhost:5050/uploads/${principalImgFilename.split("/")[4]}`
                    }
                    alt="Imagen principal de la ASADA"
                    className="object-cover h-full w-full rounded-lg shadow-2xl"
                  />
                    <DeleteImgButton 
                      onDelete={handleImageDelete}
                      setState={setPrincipalImgFilename} 
                      image={imagenes.find((item) => item.isPrincipalImg === true)} />
                </>
                ) : (
                  <div className="grid place-items-center h-full rounded-lg shadow-2xl">
                    <p className="text-xs font-light">Sin imagen</p>
                  </div>
                )}
              </div>
              <div className="form-control flex-row gap-1">
                <input
                  className="appearance-none flex-grow input-md bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-principal"
                  type="file"
                  onChange={(e) => {
                    resetButtonsDefaultState();
                    setIsPrincipalBtnDisabled(false);
                    setNombreImagen(e.target.files);
                    setTipo("principal");
                  }}
                />
                <button
                  className={`btn btn-success hover:bg-green-700 btn-md w-1/3  ${isLoading ? "loading" : ""}`}
                  type="submit"
                  disabled={isPrincipalBtnDisabled}
                >
                  {isLoading ? (
                    <></>
                  ) : (
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                      </svg>
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="text-lg mt-5">
          <form className="w-full max-w-lg" onSubmit={handleImageSubmit}>
            <div className="px-3 ">
              <label className="block tracking-wide text-xs font-bold mb-2 " htmlFor="grid-extension">
                Extensión <small>(Recomendada: 920x720px)</small>
              </label>
              <div className="mb-3 h-[180px] relative">
              {extensionImgFilename !== "" ? (
                <>
                  <img
                    src={
                      process.env.REACT_APP_NODE_ENV === "production"
                        ? `${process.env.REACT_APP_BASE_URL}/uploads/${extensionImgFilename.split("/")[4]}`
                        : `http://localhost:5050/uploads/${extensionImgFilename.split("/")[4]}`
                    }
                    alt="Imagen extensión de la ASADA"
                    className="object-cover h-full w-full rounded-lg shadow-2xl"
                  />
                  <DeleteImgButton 
                    onDelete={handleImageDelete} 
                    setState={setExtensionImgFilename}
                    image={imagenes.find((item) => item.isExtensionImg === true)} />
                </>
                ) : (
                  <div className="grid place-items-center h-full rounded-lg shadow-2xl">
                    <p className="text-xs font-light">Sin imagen</p>
                  </div>
                )}
              </div>
              <div className="form-control flex-row gap-1">
                <input
                  className="appearance-none input-md flex-grow-0 w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-extension"
                  type="file"
                  onChange={(e) => {
                    resetButtonsDefaultState();
                    setIsExtensionBtnDisabled(false);
                    setNombreImagen(e.target.files);
                    setTipo("extension");
                  }}
                />
                <button
                  className={`btn btn-success hover:bg-green-700 btn-md w-1/3 ${isLoading ? "loading" : ""}`}
                  type="submit"
                  disabled={isExtensionBtnDisabled}
                >
                  {isLoading ? (
                    <></>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="text-lg mt-5">
          <form className="w-full max-w-lg" onSubmit={handleImageSubmit}>
            <div className="px-3 ">
              <label className="block tracking-wide text-xs font-bold mb-2 " htmlFor="grid-usuariosAbastecidos">
                Usuarios Abastecidos <small>(Recomendada: 920x720px)</small>
              </label>
              <div className="mb-3 h-[180px] relative">
                {usuariosImgFilename !== "" ? (
                  <>
                    <img
                      src={
                        process.env.REACT_APP_NODE_ENV === "production"
                          ? `${process.env.REACT_APP_BASE_URL}/uploads/${usuariosImgFilename.split("/")[4]}`
                          : `http://localhost:5050/uploads/${usuariosImgFilename.split("/")[4]}`
                      }
                      alt="Usuarios abastecidos"
                      className="object-cover h-full w-full rounded-lg shadow-2xl"
                    />
                
                    <DeleteImgButton 
                      onDelete={handleImageDelete} 
                      setState={setUsuariosImgFilename}
                      image={imagenes.find((item) => item.isUsuariosImg === true)} />
                  </>
                ) : (
                  <div className="grid place-items-center h-full rounded-lg shadow-2xl">
                    <p className="text-xs font-light">Sin imagen</p>
                  </div>
                )}
              </div>
              <div className="form-control flex-row gap-1">
                <input
                  className="appearance-none input-md flex-grow w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-tanque"
                  type="file"
                  onChange={(e) => {
                    resetButtonsDefaultState();
                    setIsUsuariosBtnDisabled(false);
                    setNombreImagen(e.target.files);
                    setTipo("usuarios");
                  }}
                />
                <button
                  className={`btn btn-success hover:bg-green-700 btn-md w-1/3 ${isLoading ? "loading" : ""}`}
                  type="submit"
                  disabled={isUsuariosBtnDisabled}
                >
                  {isLoading ? (
                    <></>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="text-lg mt-5">
          <form className="w-full max-w-lg" onSubmit={handleImageSubmit}>
            <div className="px-3 ">
              <label className="block tracking-wide text-xs font-bold mb-2 " htmlFor="grid-usuariosAbastecidos">
                Tanque <small>(Recomendada: 920x720px)</small>
              </label>
              <div className="mb-3 h-[180px] relative">
              {tanqueCardImgFilename !== "" ? (
                <>
                  <img
                    src={
                      process.env.REACT_APP_NODE_ENV === "production"
                        ? `${process.env.REACT_APP_BASE_URL}/uploads/${tanqueCardImgFilename.split("/")[4]}`
                        : `http://localhost:5050/uploads/${tanqueCardImgFilename.split("/")[4]}`
                    }
                    alt="Tanque de almacenamiento"
                    className="object-cover h-full w-full rounded-lg shadow-2xl"
                  />
                    <DeleteImgButton 
                      onDelete={handleImageDelete} 
                      setState={setTanqueCardImgFilename}
                      image={imagenes.find((item) => item.isTanqueCardImg === true)} />
                </>
                ) : (
                  <div className="grid place-items-center h-full rounded-lg shadow-2xl">
                    <p className="text-xs font-light">Sin imagen</p>
                  </div>
                )}
              </div>
              <div className="form-control flex-row gap-1">
                <input
                  className="appearance-none input-md flex-grow w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-tanque"
                  type="file"
                  onChange={(e) => {
                    resetButtonsDefaultState();
                    setIsTanqueCardBtnDisabled(false);
                    setNombreImagen(e.target.files);
                    setTipo("tanqueCard");
                  }}
                />
                <button
                  className={`btn btn-success hover:bg-green-700 btn-md w-1/3${isLoading ? "loading" : ""}`}
                  type="submit"
                  disabled={isTanqueCardBtnDisabled}
                >
                  {isLoading ? (
                    <></>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="text-lg mt-5">
          <form className="w-full max-w-lg" onSubmit={handleImageSubmit}>
            <div className="px-3 ">
              <label className="block  tracking-wide text-xs font-bold mb-2 " htmlFor="grid-usuariosAbastecidos">
                Quiebragradientes <small>(Recomendada: 920x720px)</small>
              </label>
              <div className="mb-3 h-[180px] relative">
              {gradienteCardImgFilename !== "" ? (
                <>
                  <img
                    src={
                      process.env.REACT_APP_NODE_ENV === "production"
                        ? `${process.env.REACT_APP_BASE_URL}/uploads/${gradienteCardImgFilename.split("/")[4]}`
                        : `http://localhost:5050/uploads/${gradienteCardImgFilename.split("/")[4]}`
                    }
                    alt="Quiebragradientes"
                    className="object-cover h-full w-full rounded-lg shadow-2xl"
                  />
                  <DeleteImgButton 
                    onDelete={handleImageDelete} 
                    setState={setGradienteCardImgFilename}
                    image={imagenes.find((item) => item.isGradienteCardImg === true)} />
                </>
                ) : (
                  <div className="grid place-items-center h-full rounded-lg shadow-2xl">
                    <p className="text-xs font-light">Sin imagen</p>
                  </div>
                )}
              </div>
              <div className="form-control flex-row gap-1">
                <input
                  className="appearance-none input-md flex-grow w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-tanque"
                  type="file"
                  onChange={(e) => {
                    resetButtonsDefaultState();
                    setIsGradienteCardBtnDisabled(false);
                    setNombreImagen(e.target.files);
                    setTipo("gradienteCard");
                  }}
                />
                <button
                  className={`btn btn-success hover:bg-green-700 btn-md w-1/3 ${isLoading ? "loading" : ""}`}
                  type="submit"
                  disabled={isGradienteCardBtnDisabled}
                >
                  {isLoading ? (
                    <></>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="text-lg mt-5">
          <form className="w-full max-w-lg" onSubmit={handleImageSubmit}>
            <div className="px-3 ">
              <label className="block  tracking-wide text-xs font-bold mb-2 " htmlFor="grid-tanque">
                Sección de Tanque<small>(Recomendada: 1920x1280px)</small>
              </label>
              <div className="mb-3 h-[180px] relative">
                {tankImgFilename !== "" ? (
                  <>
                    <img
                      src={
                        process.env.REACT_APP_NODE_ENV === "production"
                          ? `${process.env.REACT_APP_BASE_URL}/uploads/${tankImgFilename.split("/")[4]}`
                          : `http://localhost:5050/uploads/${tankImgFilename.split("/")[4]}`
                      }
                      alt="Foto de tanque"
                      className="object-cover h-full w-full rounded-lg shadow-2xl"
                    />
                    <DeleteImgButton 
                      onDelete={handleImageDelete} 
                      setState={setTankImgFilename}
                      image={imagenes.find((item) => item.isTanqueImg === true)}/>
                  </>
                ) : (
                  <div className="grid place-items-center h-full rounded-lg shadow-2xl">
                    <p className="text-xs font-light">Sin imagen</p>
                  </div>
                )}
              </div>
              <div className="form-control flex-row gap-1">
                <input
                  className="appearance-none input-md flex-grow w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-tanque"
                  type="file"
                  onChange={(e) => {
                    resetButtonsDefaultState();
                    setIsTanqueBtnDisabled(false);
                    setNombreImagen(e.target.files);
                    setTipo("tanque");
                  }}
                />
                <button
                  className={`btn btn-success hover:bg-green-700 btn-md w-1/3 ${isLoading ? "loading" : ""}`}
                  type="submit"
                  disabled={isTanqueBtnDisabled}
                >
                  {isLoading ? (
                    <></>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="text-lg mt-5">
          <form className="w-full max-w-lg" onSubmit={handleImageSubmit}>
            <div className="px-3 ">
              <label className="block tracking-wide text-xs font-bold mb-2 " htmlFor="grid-gradiente">
                Sección de Quiebragradientes
                <small>(Recomendada: 1920x1280px)</small>
              </label>
              <div className="mb-3 h-[180px] relative">
              {gradienteImgFilename !== "" ? (
                <>
                  <img
                    src={
                      process.env.REACT_APP_NODE_ENV === "production"
                        ? `${process.env.REACT_APP_BASE_URL}/uploads/${gradienteImgFilename.split("/")[4]}`
                        : `http://localhost:5050/uploads/${gradienteImgFilename.split("/")[4]}`
                    }
                    alt="Foto de gradiente"
                    className="object-cover h-full w-full rounded-lg shadow-2xl"
                  />
                  <DeleteImgButton 
                    onDelete={handleImageDelete} 
                    setState={setGradienteImgFilename}
                    image={imagenes.find((item) => item.isGradienteImg === true)} />
                </>
              ) : (
                <div className="grid place-items-center h-full rounded-lg shadow-2xl">
                  <p className="text-xs font-light">Sin imagen</p>
                </div>
              )}
              </div>
              <div className="form-control flex-row gap-1">
                <input
                  className="appearance-none input-md flex-grow w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-gradiente"
                  type="file"
                  onChange={(e) => {
                    resetButtonsDefaultState();
                    setIsGradienteBtnDisabled(false);
                    setNombreImagen(e.target.files);
                    setTipo("gradiente");
                  }}
                />
                <button
                  className={`btn btn-success hover:bg-green-700 btn-md w-1/3 ${isLoading ? "loading" : ""}`}
                  type="submit"
                  disabled={isGradienteBtnDisabled}
                >
                  {isLoading ? (
                    <></>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="text-lg mt-5">
          <form className="w-full max-w-lg" onSubmit={handleImageSubmit}>
            <div className="px-3 ">
              <label className="block tracking-wide text-xs font-bold mb-2 " htmlFor="grid-gradiente">
                Sección de Pozos
                <small>(Recomendada: 1920x1280px)</small>
              </label>
              <div className="mb-3 h-[180px] relative">
              {pozoImgFilename !== "" ? (
                <>
                  <img
                    src={
                      process.env.REACT_APP_NODE_ENV === "production"
                        ? `${process.env.REACT_APP_BASE_URL}/uploads/${pozoImgFilename.split("/")[4]}`
                        : `http://localhost:5050/uploads/${pozoImgFilename.split("/")[4]}`
                    }
                    alt="Foto de pozo de agua"
                    className="object-cover h-full w-full rounded-lg shadow-2xl"
                  />
                  <DeleteImgButton 
                    onDelete={handleImageDelete} 
                    setState={setPozoImgFilename}
                    image={imagenes.find((item) => item.isPozoImg === true)} />
                </>
              ) : (
                <div className="grid place-items-center h-full rounded-lg shadow-2xl">
                  <p className="text-xs font-light">Sin imagen</p>
                </div>
              )}
              </div>
              <div className="form-control flex-row gap-1">
                <input
                  className="appearance-none input-md flex-grow w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-gradiente"
                  type="file"
                  onChange={(e) => {
                    resetButtonsDefaultState();
                    setIsPozoBtnDisabled(false);
                    setNombreImagen(e.target.files);
                    setTipo("pozo");
                  }}
                />
                <button
                  className={`btn btn-success hover:bg-green-700 btn-md w-1/3 ${isLoading ? "loading" : ""}`}
                  type="submit"
                  disabled={isPozoBtnDisabled}
                >
                  {isLoading ? (
                    <></>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="text-lg mt-5">
          <form className="w-full max-w-lg" onSubmit={handleImageSubmit}>
            <div className="px-3 ">
              <label className="block tracking-wide text-xs font-bold mb-2 " htmlFor="grid-servicios">
                Sección de Servicios <small>(Recomendada: 1920x1280px)</small>
              </label>
              <div className="mb-3 h-[180px] relative">
              {serviciosImgFilename != "" ? (
                <>
                  <img
                    src={
                      process.env.REACT_APP_NODE_ENV === "production"
                        ? `${process.env.REACT_APP_BASE_URL}/uploads/${serviciosImgFilename.split("/")[4]}`
                        : `http://localhost:5050/uploads/${serviciosImgFilename.split("/")[4]}`
                    }
                    alt="Foto de sección de servicios"
                    className="object-cover h-full w-full rounded-lg shadow-2xl"
                  />
                  <DeleteImgButton 
                    onDelete={handleImageDelete} 
                    setState={setServiciosImgFilename}
                    image={imagenes.find((item) => item.isServiciosImg === true)} />
                </>
              ) : (
                <div className="grid place-items-center h-full rounded-lg shadow-2xl">
                  <p className="text-xs font-light">Sin imagen</p>
                </div>
              )}
              </div>
              <div className="form-control flex-row gap-1">
                <input
                  className="appearance-none input-md flex-grow w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-servicios"
                  type="file"
                  onChange={(e) => {
                    resetButtonsDefaultState();
                    setIsServiciosBtnDisabled(false);
                    setNombreImagen(e.target.files);
                    setTipo("servicios");
                  }}
                />
                <button
                  className={`btn btn-success hover:bg-green-700 btn-md w-1/3 ${isLoading ? "loading" : ""}`}
                  type="submit"
                  disabled={isServiciosBtnDisabled}
                >
                  {isLoading ? (
                    <></>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="text-lg mt-5">
          <form className="w-full max-w-lg" onSubmit={handleImageSubmit}>
            <div className="px-3">
              <label className="block  tracking-wide text-xs font-bold mb-2 " htmlFor="grid-servicios">
                Sección de Proyectos <small>(Recomendada: 1280x1920px)</small>
              </label>
              <div className="mb-3 h-[180px] relative">
              {proyectosImgFilename != "" ? (
                <>
                  <img
                    src={
                      process.env.REACT_APP_NODE_ENV === "production"
                        ? `${process.env.REACT_APP_BASE_URL}/uploads/${proyectosImgFilename.split("/")[4]}`
                        : `http://localhost:5050/uploads/${proyectosImgFilename.split("/")[4]}`
                    }
                    alt="Foto de sección de proyectos"
                    className="object-cover h-full w-full rounded-lg shadow-2xl"
                  />
                  <DeleteImgButton 
                    onDelete={handleImageDelete} 
                    setState={setProyectosImgFilename}
                    image={imagenes.find((item) => item.isProyectosImg === true)} />

                </>
                ) : (
                  <div className="grid place-items-center h-full rounded-lg shadow-2xl">
                    <p className="text-xs font-light">Sin imagen</p>
                  </div>
                )}
              </div>
              <div className="form-control flex-row gap-1">
                <input
                  className="appearance-none input-md flex-grow w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-proyectos"
                  type="file"
                  onChange={(e) => {
                    resetButtonsDefaultState();
                    setIsProyectosBtnDisabled(false);
                    setNombreImagen(e.target.files);
                    setTipo("proyectos");
                  }}
                  
                />
                <button
                  className={`btn btn-success hover:bg-green-700 btn-md w-1/3 ${isLoading ? "loading" : ""}`}
                  type="submit"
                  disabled={isProyectosBtnDisabled}
                >
                  {isLoading ? (
                    <></>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="divider"></div>
      <h2>Página de Nosotros</h2>
      <p className="text-base font-light">Estas imágenes aparecerán en la página pública de Nosotros de la ASADA</p>
      <div className="flex flex-wrap gap-2">
        <div className="text-lg mt-5">
          <form className="w-full max-w-lg" onSubmit={handleImageSubmit}>
            <div className="px-3 ">
              <label className="block tracking-wide text-xs font-bold mb-2 " htmlFor="grid-nosotros">
                Sección de Nosotros <small>(Recomendada: 1280x720px)</small>
              </label>
              <div className="mb-3 h-[180px] relative">
              {nosotrosImgFilename != "" ? (
                <>
                  <img
                    src={
                      process.env.REACT_APP_NODE_ENV === "production"
                        ? `${process.env.REACT_APP_BASE_URL}/uploads/${nosotrosImgFilename.split("/")[4]}`
                        : `http://localhost:5050/uploads/${nosotrosImgFilename.split("/")[4]}`
                    }
                    alt="Imagen de página nosotros"
                    className="object-cover h-full w-full rounded-lg shadow-2xl"
                  />
                  <DeleteImgButton 
                    onDelete={handleImageDelete} 
                    setState={setNosotrosImgFilename}
                    image={imagenes.find((item) => item.isNosotrosImg === true)} />
                </>
              ) : (
                <div className="grid place-items-center h-full rounded-lg shadow-2xl">
                  <p className="text-xs font-light">Sin imagen</p>
                </div>
              )}
              </div>
              <div className="form-control flex-row gap-1">
                <input
                  className="appearance-none input-md flex-grow w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-nosotros"
                  type="file"
                  onChange={(e) => {
                    resetButtonsDefaultState();
                    setIsNosotrosBtnDisabled(false);
                    setNombreImagen(e.target.files);
                    setTipo("nosotros");
                  }}
                />
                <button
                  className={`btn btn-success hover:bg-green-700 btn-md w-1/3 ${isLoading ? "loading" : ""}`}
                  type="submit"
                  disabled={isNosotrosBtnDisabled}
                >
                  {isLoading ? (
                    <></>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="divider"></div>
      <h2>Página de Documentación</h2>
      <p className="text-base font-light">
        Estas imágenes aparecerán en la página pública de Documentación de la ASADA
      </p>
      <div className="flex flex-wrap gap-2">
        <div className="text-lg mt-5">
          <form className="w-full max-w-lg" onSubmit={handleImageSubmit}>
            <div className="px-3 ">
              <label className="block  tracking-wide text-xs font-bold mb-2 " htmlFor="grid-nosotros">
                Sección de Documentación <small>(Recomendada: 1920x1080px)</small>
              </label>
              <div className="mb-3 h-[180px] relative">
              {documentacionImgFilename != "" ? (
                <>
                    <img
                    src={
                      process.env.REACT_APP_NODE_ENV === "production"
                        ? `${process.env.REACT_APP_BASE_URL}/uploads/${documentacionImgFilename.split("/")[4]}`
                        : `http://localhost:5050/uploads/${documentacionImgFilename.split("/")[4]}`
                    }
                    alt="Imagen de página documentación"
                    className="object-cover h-full w-full rounded-lg shadow-2xl"
                  />
                    <DeleteImgButton 
                      onDelete={handleImageDelete} 
                      setState={setDocumentacionImgFilename}
                      image={imagenes.find((item) => item.isDocumentacionImg === true)} />

                </>
              ) : (
                <div className="grid place-items-center h-full rounded-lg shadow-2xl">
                  <p className="text-xs font-light">Sin imagen</p>
                </div>
              )}
              
              </div>
              <div className="form-control flex-row gap-1">
                <input
                  className="appearance-none input-md flex-grow w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-nosotros"
                  type="file"
                  onChange={(e) => {
                    resetButtonsDefaultState();
                    setIsDocumentacionBtnDisabled(false);
                    setNombreImagen(e.target.files);
                    setTipo("documentacion");
                  }}
                />
                <button
                  className={`btn btn-success hover:bg-green-700 btn-md w-1/3 ${isLoading ? "loading" : ""}`}
                  type="submit"
                  disabled={isDocumentacionBtnDisabled}
                >
                  {isLoading ? (
                    <></>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <div>
          <p className="font-light text-lg">Consideraciones</p>
          <ul className="list-disc">
            <li className="font-light text-sm my-1">
              En esta sección puede editar las fotos que se muestran en la página pública.
            </li>
            <li className="font-light text-sm my-1">
              Los botones para guardar la imagen se activan cuando se selecciona una imagen.
            </li>
            <li className="font-light text-sm my-1">
              Al lado del nombre de la imagen se muestra el tamaño recomendado que debería tener la foto para verse
              correctamente en la página pública.
            </li>
            <li className="font-light text-sm my-1">La imagen no debe pesar más de 3MB.</li>
          </ul>
        </div>
      </Modal>
    </>
  );
}

export default ImagenesAsada;
