import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import JuntaDirectivaContext from "../../context/juntaDirectiva/JuntaDirectivaContext";
import Modal from "../shared/Modal";
import LoginContext from "../../context/login/LoginContext";

function JuntaDirectivaAdd() {
  const { addIntegrante, isLoading, setIsLoading } = useContext(JuntaDirectivaContext);
  const { user } = useContext(LoginContext);

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [position, setPosition] = useState("");
  const [vigency, setVigency] = useState("");
  const [photo, setPhoto] = useState("");

  const handleNameChange = (e) => setName(e.target.value);
  const handleLastNameChange = (e) => setLastName(e.target.value);
  const handlePositionChange = (e) => setPosition(e.target.value);
  const handleVigencyChange = (e) => setVigency(e.target.value);
  const handlePhotoChange = (e) => setPhoto(e.target.files[0]);

  const dateToTimestamp = (str) => new Date(str).toISOString();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  // Función para cargar una imagen desde una URL y convertirla en un objeto File
  async function urlToFile(url, filename, mimeType) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.set("nombre", name);
    fd.set("apellidos", lastName);
    fd.set("cargo", position);
    fd.set("vigencia", dateToTimestamp(vigency));

    // If there is no photo selected, use the default avatar image
    if (photo === "") {
      const avatarFile = await urlToFile("https://asadascr.com/icons/avatar.png", "avatar.png", "image/png");
      fd.append("foto", avatarFile);
    } else fd.append("foto", photo);

    const searchParams = new URLSearchParams(window.location.search);
    const asadaId = searchParams.get("aid");
    // If asadaId exists, it means the person creating the integrante is an admin
    // The admin should have a url like this: http://localhost:3000/admin/integrantes/add?aid=5
    if (asadaId) await addIntegrante(fd, asadaId);
    else await addIntegrante(fd, user.asadaId);

    formReset();
  };

  const formReset = () => {
    setName("");
    setLastName("");
    setPosition("");
    setVigency("");
    setPhoto("");
  };

  return (
    <div className="rounded-lg rounded-tl-none p-7 shadow-lg card bg-base-100 border-t-none ">
      <h2 className="text-2xl my-4 font-bold card-title">
        <button onClick={() => navigate(-1)} className="btn btn-outline btn-accent btn-sm hover:text-white">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 " viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        Agregar nuevo integrante
      </h2>

      <div className="text-lg flex justify-center">
        <form className="w-full max-w-lg" onSubmit={handleSubmit} autoComplete="off">
          <div className="w-full px-3">
            <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-name">
              Nombre *
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-title"
              type="text"
              required
              name="nombre"
              placeholder="Nombre del integrante"
              onChange={handleNameChange}
            />
          </div>
          <div className="w-full px-3">
            <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-last_name">
              Apellidos *
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last_name"
              type="text"
              required
              name="apellidos"
              placeholder="Apellidos del integrante"
              onChange={handleLastNameChange}
            />
          </div>

          <div className="w-full px-3">
            <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-vigency">
              Vigencia *
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-vigency"
              type="date"
              required
              name="vigencia"
              onChange={handleVigencyChange}
            />
          </div>
          <div className="w-full px-3">
            <label className="block inline tracking-wide text-xs font-bold mb-2" htmlFor="grid-position">
              Cargo *
            </label>

            <div className="form-control">
              <select
                className="select select-md select-accent w-full max-w-xs mb-2"
                onChange={handlePositionChange}
                defaultValue="0"
              >
                <option value="0" disabled>
                  Seleccionar cargo:
                </option>
                <option value={"presidente"} defaultValue={"presidente"}>
                  Presidente
                </option>
                <option value={"vicePresidente"}>Vice Presidente</option>
                <option value={"secretario"}>Secretario(a)</option>
                <option value={"tesorero"}>Tesorero(a)</option>
                <option value={"fiscal"}>Fiscal</option>
                <option value={"vocal"}>Vocal</option>
              </select>
            </div>
          </div>
          <div className="w-full px-3 mt-3">
            <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-photo">
              Foto
              <small className="ml-1 text-2xs text-gray-500">(Formato permitido: .png, .jpg, .jpeg )</small>
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-photo"
              type="file"
              lang="es"
              name="photo"
              onChange={handlePhotoChange}
            />
            <small className="ml-1 text-2xs text-gray-500">* Campos requeridos</small>
          </div>

          <div className="divider"></div>

          <div className="card-actions justify-start">
            <button
              type="submit"
              className={`btn btn-success hover:bg-green-700 capitalize ${isLoading ? "loading" : ""}`}
            >
              Agregar&nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
              </svg>
            </button>
            <button type="button" className="btn btn-ghost ml-2 justify-self-end" onClick={openModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <div>
          <p className="font-light text-lg">Consideraciones</p>
          <ul className="list-disc">
            <li className="font-light text-sm my-1">Los campos requeridos están marcados con un asterisco (*).</li>
            <li className="font-light text-sm my-1">El nombre debe tener más de 3 y menos de 70 caracteres.</li>
            <li className="font-light text-sm my-1">Los apellidos deben tener menos de 100 caracteres entre ambos.</li>
            <li className="font-light text-sm my-1">La foto debe ser en formato png, jpg, o jpeg.</li>
            <li className="font-light text-sm my-1">La foto no debe pesar más de 3MB.</li>
            <li className="font-light text-sm my-1">
              Las dimensiones recomendadas para la foto son 580x680px (orientación vertical).
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default JuntaDirectivaAdd;
