import { useState } from "react";

const InputSearchAsada = ({ options, setAsada }) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleInputChange = (event) => {
    const input = event.target.value;
    setInputValue(input);
    const filtered = options.filter((option) => {
      const { nombre, num_empresa_cisa } = option;
      return (
        nombre.toLowerCase().includes(input.toLowerCase()) ||
        num_empresa_cisa.toString().toLowerCase().includes(input.toLowerCase())
      );
    });
    setFilteredOptions(filtered.slice(0, 5));
  };

  const handleOptionClick = (option) => {
    setInputValue(option.nombre);
    setAsada(option);
    setFilteredOptions([]);
  };

  return (
    <div className='w-full'>
      <input
        type='text'
        className='text-gray-800 text-center w-full text-lg'
        value={inputValue}
        onChange={handleInputChange}
        placeholder='Buscar por nombre o número de empresa'
      />
      {inputValue !== "" && (
        <>
          {filteredOptions.length > 0 && (
            <div className='bg-base-200 py-5'>
              {filteredOptions.map((option) => (
                <div
                  key={option.id}
                  onClick={() => handleOptionClick(option)}
                  className='text-gray-300 cursor-pointer text-lg text-center hover:bg-accent hover:text-gray-700'
                >
                  {option.nombre} - {option.num_empresa_cisa}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InputSearchAsada;
