import { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import JuntaDirectivaTable from "./JuntaDirectivaTable";
import JuntaDirectivaContext from "../../context/juntaDirectiva/JuntaDirectivaContext";
import Spinner from "../shared/Spinner";
import Modal from "../shared/Modal";
import LoginContext from "../../context/login/LoginContext";

function JuntaDirectiva() {
  const { isLoading, getIntegrantes, integrantes } = useContext(
    JuntaDirectivaContext
  );
  const { user } = useContext(LoginContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    const fetchIntegrantes = async () => {
      await getIntegrantes(user.asadaId);
    };
    fetchIntegrantes();
  }, []);

  return (
    <section>
      <div className='flex justify-between items-center'>
        <h2 className='text-2xl my-4 font-bold card-title'>
          Integrantes de Junta Directiva
          <button
            type='button'
            className='btn btn-ghost ml-2'
            onClick={openModal}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z'
              />
            </svg>
          </button>
        </h2>
      </div>
      <div className='form-action-btns h-10 w-full flex justify-end'>
        <Link to={"add"} className={`btn btn-accent btn-sm capitalize`}>
          Agregar Integrante&nbsp;
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5'
            viewBox='0 0 20 20'
            fill='currentColor'
          >
            <path d='M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z' />
          </svg>
        </Link>
      </div>
      <div className='overflow-x-auto max-h-full'>
        {!isLoading && integrantes ? (
          <JuntaDirectivaTable integrantes={integrantes} />
        ) : (
          <Spinner />
        )}
      </div>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <div>
          <p className='font-light text-lg'>Consideraciones</p>
          <ul className='list-disc'>
            <li className='font-light text-sm my-1'>
              Los datos de la tabla se ordenan alfabéticamente al hacer clic en
              el nombre de la columna.
            </li>
            <li className='font-light text-sm my-1'>
              Los botones 'Anterior' y 'Siguiente' se activan cuando hay más de
              10 integrantes de junta directiva.
            </li>
          </ul>
        </div>
      </Modal>
    </section>
  );
}

export default JuntaDirectiva;
