function Modal({ isModalOpen, closeModal, children }) {
  return (
    <>
      <div className={`modal ${isModalOpen ? "modal-open" : ""} px-3`}>
        <div className='modal-box'>
          <span className='cursor-pointer flex justify-end mb-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-10 w-10 z-50'
              viewBox='0 0 20 20'
              onClick={closeModal}
              fill='#37CDBE'
            >
              <path
                fillRule='evenodd'
                d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                clipRule='evenodd'
                fill='#37CDBE'
              />
            </svg>
          </span>
          <div className='card bg-neutral text-neutral-content'>
            <div className='card-body'>
              <h2 className='card-title justify-center'>Menú de Ayuda</h2>

              {children}

              <p className='font-light text-lg'>Soporte</p>

              <ul className='list-none'>
                <li className='font-light text-sm my-1'>
                  Correo electrónico:{" "}
                  <a
                    href='mailto:servicioalcliente@shlcisa.com'
                    className='underline text-blue-500 hover:text-blue-700 visited:text-purple-600'
                  >
                    servicioalcliente@shlcisa.com
                  </a>
                </li>
                <li className='font-light text-sm my-1'>
                  Teléfonos:
                  <ul className='list-disc'>
                    <li className='ml-5'>4300-4933</li>
                    <li className='ml-5'>4300-4934</li>
                    <li className='ml-5'>4300-4935</li>
                  </ul>
                </li>
              </ul>
              <p className='font-light text-lg'>Acerca de</p>
              <ul className='list-none font-light text-xs'>
                <li className=''>Nombre del sistema: MOGEAS</li>
                <li className=''>Versión del sistema: 1.0.0</li>
                <li className=''>
                  Nombre de la empresa: Corporación Internacional de Servicios
                  Administrativos
                </li>
                <li>
                  Diseñado y programado por:
                  <ul className='list-disc'>
                    <li className='ml-5'>Jurgen Alfaro Morera</li>
                    <li className='ml-5'>Braulio Alfaro Castro</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
