import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import bgImage from "../../../assets/bg_aguapura.jpg";
import AsadaContext from "../../../context/asada/asadaContext";

function LandingPage({ asada }) {
  const { isLoading } = useContext(AsadaContext);
  const { nombre, fotoEstaticas, slogan } = asada;
  const [principalImg, setPrincipalImg] = useState("");

  useEffect(() => {
    if (fotoEstaticas !== undefined) {
      if (fotoEstaticas.length > 0) {
        const principalImg = fotoEstaticas.filter((item) => item.isPrincipalImg === true)[0];

        if (principalImg !== undefined) setPrincipalImg(principalImg.path.split("/")[4]);
      }
    }
  }, [fotoEstaticas]);

  return (
    <>
      <div className="hero ">
        <div className="video-container border-2">
          {isLoading ? (
            <></>
          ) : (
            <img
              src={
                process.env.REACT_APP_NODE_ENV === "production"
                  ? `${principalImg !== "" ? `${process.env.REACT_APP_BASE_URL}/uploads/${principalImg}` : bgImage}`
                  : `${principalImg !== "" ? `http://localhost:5050/uploads/${principalImg}` : bgImage}`
              }
              alt=""
            ></img>
          )}
        </div>
      </div>
      <div className="w-full my-20 p-4 flex flex-col place-items-center gap-10">
        <h1 className="text-4xl md:text-6xl lg:text-7xl lg:max-w-2xl text-center font-bold h-full">{nombre}</h1>
        <p className="max-w-xl text-center">{slogan}</p>
        <Link to="/servicios" className="btn btn-accent lg:btn-lg hover:bg-slate-300 duration-500 capitalize">
          Consultar Recibos&nbsp;
          <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill="#FFFFFF">
            <path d="M2.8 22.325V1.7l1.55 1.55 1.5-1.55L7.4 3.25 8.925 1.7l1.55 1.55L12 1.7l1.525 1.55 1.55-1.55L16.6 3.25l1.55-1.55 1.525 1.55L21.2 1.7v20.625L19.675 20.8l-1.525 1.525L16.6 20.8l-1.525 1.525-1.55-1.525L12 22.325 10.475 20.8l-1.55 1.525L7.4 20.8l-1.55 1.525L4.325 20.8Zm3.275-5.275h11.85v-2.1H6.075Zm0-4h11.85v-2.1H6.075Zm0-4h11.85v-2.1H6.075Z" />
          </svg>
        </Link>
      </div>
    </>
  );
}

export default LandingPage;
