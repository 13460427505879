import { useContext, useState } from "react";
import PozosContext from "../../context/pozosContext";
import { toast } from "react-toastify";

function PozoPhotos({ photo }) {
  const { deletePozoPhoto, pozo } = useContext(PozosContext);
  const [photoId, setPhotoId] = useState(photo.id);
  const photoUrl =
    process.env.REACT_APP_NODE_ENV === "production"
      ? `${process.env.REACT_APP_BASE_URL}/uploads/${photo.path.split("/")[4]}`
      : `http://localhost:5050/uploads/${photo.path.split("/")[4]}`;

  const handleClick = (e) => {
    e.preventDefault();
    if (
      window.confirm(
        "Se eliminará de la base de datos la foto del pozo. Esta acción no se puede revertir.\n ¿Seguro que desea continuar?"
      )
    )
    try {
        deletePozoPhoto(photoId, pozo.id);
        toast.info(`Foto eliminada`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } catch (error) {
        console.log(error)
        toast.error(`Ha ocurrido un error al borrar foto`, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
  };

  return (
    <>
      <div className={`w-full relative h-96 transition duration-150 ease-out `}>
        <img className="w-full h-full object-cover" alt="Pozo image" src={photoUrl} />

        <button
          className="btn btn-sm btn-square hover:btn-error absolute top-4 right-4 z-10"
          type="button"
          onClick={handleClick}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </>
  );
}

export default PozoPhotos;
