import { useContext, useEffect, useState } from "react";
import SuggestionContext from "../../context/suggestions/SuggestionContext";
import Spinner from "../shared/Spinner";
import "moment/locale/es";
import SuggestionTable from "./SuggestionTable";
import Modal from "../shared/Modal";
import LoginContext from "../../context/login/LoginContext";

function SuggestionList() {
  const { isLoading, getAllSugerencias, sugerencias } =
    useContext(SuggestionContext);
  const { user } = useContext(LoginContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    getAllSugerencias(user.asadaId);
  }, []);

  return (
    <div className='rounded-lg shadow-lg card bg-base-100'>
      <div className='card-body'>
        <div className='flex justify-between items-center'>
          <h2 className='text-2xl my-4 font-bold card-title'>
            Sugerencias y Comentarios{" "}
            <button
              type='button'
              className='btn btn-ghost ml-2 justify-self-end'
              onClick={openModal}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z'
                />
              </svg>
            </button>
          </h2>
        </div>

        {!isLoading ? (
          <div className='overflow-x-auto flex justify-center'>
            <div>
              {sugerencias.length === 0 ? (
                <p className='text-center'>Sin sugerencias</p>
              ) : (
                <SuggestionTable />
              )}
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <div>
          <p className='font-light text-lg'>Consideraciones</p>
          <ul className='list-disc'>
            <li className='font-light text-sm my-1'>
              Los datos de la tabla se ordenan alfabéticamente al hacer clic en
              el nombre de la columna.
            </li>
            <li className='font-light text-sm my-1'>
              Los botones 'Anterior' y 'Siguiente' se activan cuando hay más de
              10 sugerencias/comentarios.
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default SuggestionList;
