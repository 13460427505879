import { useState, useEffect } from "react";
import { Parallax, Background } from "react-parallax";
import { NavLink } from "react-router-dom";
import useWindowDimensions from "../../../components/hooks/useWindowDimensions";
import image1 from "../../../assets/home-img-7.jpg";

function HomeProjects({ asada }) {
  const { width } = useWindowDimensions();

  const { texto_proyectos, fotoEstaticas } = asada;

  const [proyectosImgFilename, setProyectosImgFilename] = useState("");

  useEffect(() => {
    if (fotoEstaticas !== undefined) {
      if (fotoEstaticas.length > 0) {
        const proyectoImg = fotoEstaticas.filter(
          (item) => item.isProyectosImg === true
        )[0];

        if (proyectoImg !== undefined)
          setProyectosImgFilename(proyectoImg.path.split("/")[4]);
      }
    }
  }, [fotoEstaticas]);

  if (width > 768) {
    return (
      <section className='home-projects flex'>
        <div className='projects-info flex flex-col justify-start bg-accent-focus'>
          <h2 className='text-3xl xl:text-6xl text-white px-5 mb-5'>
            Nuestros Proyectos
          </h2>
          <p className='text-lg px-5 py-6 text-white'>
            {asada && texto_proyectos}
          </p>
          <NavLink
            to='proyectos'
            className='btn bg-slate-300 text-gray-600 hover:bg-accent mt-5 btn-lg border-slate-300 hover:border-accent-focus capitalize'
          >
            Ver proyectos{" "}
          </NavLink>
        </div>

        <div className='projects-image'>
          {proyectosImgFilename !== "" ? (
            <Parallax strength={400}>
              <Background>
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/uploads/${proyectosImgFilename}`}
                  alt='Imagen sección de proyectos'
                ></img>
              </Background>
            </Parallax>
          ) : (
            <Parallax bgImage={image1} strength={400}>
              <div></div>
            </Parallax>
          )}
        </div>
      </section>
    );
  } else {
    return (
      <section className='home-projects flex'>
        <div className='projects-info flex flex-col justify-start'>
          <h2 className='lg:text-7xl md:text-4xl text-3xl text-white px-5'>
            Nuestros Proyectos
          </h2>

          <NavLink
            to='projects'
            className='btn bg-slate-300 hover:bg-accent mt-5 btn-lg capitalize'
          >
            Ver proyectos{" "}
          </NavLink>
        </div>
      </section>
    );
  }
}

export default HomeProjects;
