import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import BancoProcesadorContext from "../../context/bancoProcesadorContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; //React-Toastify CSS
import Modal from "../shared/Modal";

function BancosProcesadoresAdminAdd() {
    const { isLoading, createBancoProcesador } = useContext(
        BancoProcesadorContext
    );
    const [acronimo, setAcronimo] = useState("");
    const [nombre, setNombre] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    const openModal = () => {
        setIsModalOpen(true);
        document.body.style.overflow = "hidden";
    };

    const closeModal = () => {
        setIsModalOpen(false);
        document.body.style.overflow = "auto";
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!acronimo || !nombre) {
            toast.error(`Debe ingresar algún valor`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        const bancoObj = {
            acronimo,
            nombre,
        };

        try {
            const response = await createBancoProcesador(bancoObj);
            toast.success(`Banco procesador creado exitosamente`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            toast.error(`Hubo un error al crear el banco procesador`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.log(error);
        }
    };

    return (
        <div className="rounded-lg shadow-lg card bg-base-100">
            <div className="card-body ">
                <h2 className="text-2xl my-4 font-bold card-title">
                    <button
                        onClick={() => navigate(-1)}
                        className="btn btn-outline btn-accent btn-sm hover:text-white"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 "
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                    Agregar nuevo banco
                </h2>
                <div className="flex justify-center text-lg">
                    <form className="w-full max-w-lg" onSubmit={handleSubmit}>
                        <div className="w-full px-3">
                            <label
                                className="block capitalize tracking-wide  text-xs font-bold mb-2"
                                htmlFor="grid-acronimo"
                            >
                                Acrónimo *
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-acronimo"
                                type="text"
                                required
                                name="acronimo"
                                placeholder="Acrónimo del banco"
                                onChange={(e) => setAcronimo(e.target.value)}
                                value={acronimo}
                            />
                        </div>
                        <div className="w-full px-3">
                            <label
                                className="block capitalize tracking-wide  text-xs font-bold mb-2"
                                htmlFor="grid-nombre"
                            >
                                Nombre *
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-nombre"
                                type="text"
                                required
                                name="nombre"
                                placeholder="Nombre de banco"
                                onChange={(e) => setNombre(e.target.value)}
                                value={nombre}
                            />
                        </div>
                        <small className="ml-1 text-2xs text-gray-500">
                            * Campos requeridos
                        </small>
                        <div className="divider"></div>

                        <div className="card-actions justify-start">
                            <button
                                type="submit"
                                className={`btn btn-success hover:bg-green-700 capitalize ${
                                    isLoading ? "loading" : ""
                                }`}
                            >
                                {!isLoading ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                                    </svg>
                                ) : (
                                    <></>
                                )}
                                &nbsp;Agregar
                            </button>
                            <button
                                type="button"
                                className="btn btn-ghost ml-2 justify-self-end"
                                onClick={openModal}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
                <div className="overflow-x-auto flex flex-col items-center justify-center">
                    <p className="font-light text-lg">Consideraciones</p>
                    <ul className="list-disc">
                        <li className="font-light text-sm my-1">
                            Los campos requeridos están marcados con un
                            asterisco (*).
                        </li>
                    </ul>
                </div>
            </Modal>
        </div>
    );
}

export default BancosProcesadoresAdminAdd;
