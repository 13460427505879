import { SuggestionProvider } from "../../context/suggestions/SuggestionContext";
import { JobProvider } from "../../context/jobs/JobContext";
import { PostProvider } from "../../context/posts/PostContext";
import { FormProvider } from "../../context/forms/FormContext";
import { JuntaDirectivaProvider } from "../../context/juntaDirectiva/JuntaDirectivaContext";
import { TransparenciaProvider } from "../../context/transparencia/TransparenciaContext";
import { ReglamentosProvider } from "../../context/reglamentos/ReglamentosContext";
import { ProjectProvider } from "../../context/projects/ProjectContext";
import { TanksProvider } from "../../context/tanks/TanksContext";
import { QuiebraGradientesProvider } from "../../context/quiebraGradientes/QuiebraGradientesContext";
import { AsadaProvider } from "../../context/asada/asadaContext";
import { InformesProvider } from "../../context/informes/InformesContext";
import { UserProvider } from "../../context/users/UserContext";
import { BancoProcesadorProvider } from "../../context/bancoProcesadorContext";
import { PozosProvider } from "../../context/pozosContext";

function LayoutProvider({ children }) {
    return (
        <SuggestionProvider>
            <UserProvider>
                <JobProvider>
                    <PostProvider>
                        <FormProvider>
                            <JuntaDirectivaProvider>
                                <TransparenciaProvider>
                                    <InformesProvider>
                                        <ReglamentosProvider>
                                            <ProjectProvider>
                                                <TanksProvider>
                                                    <QuiebraGradientesProvider>
                                                        <AsadaProvider>
                                                            <BancoProcesadorProvider>
                                                                <PozosProvider>
                                                                    {children}
                                                                </PozosProvider>
                                                            </BancoProcesadorProvider>
                                                        </AsadaProvider>
                                                    </QuiebraGradientesProvider>
                                                </TanksProvider>
                                            </ProjectProvider>
                                        </ReglamentosProvider>
                                    </InformesProvider>
                                </TransparenciaProvider>
                            </JuntaDirectivaProvider>
                        </FormProvider>
                    </PostProvider>
                </JobProvider>
            </UserProvider>
        </SuggestionProvider>
    );
}

export default LayoutProvider;
