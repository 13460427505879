import { Outlet, NavLink } from "react-router-dom";

function AsadaGeneralInfo() {
  return (
    <>
      <div className="relative max-h-max w-full bg-slate-900">
        <div className="tabs">
          <NavLink
            to="general"
            className={({ isActive }) => (isActive ? "tab tab-lifted tab-active" : "tab tab-lifted")}
          >
            Información general
          </NavLink>
          <NavLink
            to="configuraciones"
            className={({ isActive }) => (isActive ? "tab tab-lifted tab-active" : "tab tab-lifted")}
          >
            Configuraciones
          </NavLink>
        </div>
        <div className="rounded-lg rounded-tl-none p-7 shadow-lg card bg-base-100 border-t-none ">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default AsadaGeneralInfo;
