import { useEffect, useContext, useState, useLayoutEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import QuiebraGradientesContext from "../../context/quiebraGradientes/QuiebraGradientesContext";
import GradientePhotos from "./GradientePhotos";
import Spinner from "../shared/Spinner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoginContext from "../../context/login/LoginContext";
import Modal from "../shared/Modal";
import { toast } from "react-toastify";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        transform: "scale(2)",
        right: "25px",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        transform: "scale(2)",
        left: "25px",
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
}

function GradienteItem() {
  const {
    gradiente,
    setGradiente,
    getSingleGradiente,
    isLoading,
    updateGradiente,
    deleteGradiente,
    updateGradienteAdmin,
  } = useContext(QuiebraGradientesContext);
  const { user } = useContext(LoginContext);

  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const [newName, setNewName] = useState("");
  const [newLocation, setNewLocation] = useState("");
  const [newCapacity, setNewCapacity] = useState("");
  const [newCosto, setNewCosto] = useState(0);
  const [newProveedor, setNewProveedor] = useState("");
  const [newPhotos, setNewPhotos] = useState("");
  const [forceDelete, setForceDelete] = useState(false);
  const [restore, setRestore] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const { nombre, ubicacion, capacidad, costo, proveedor, fotos, deletedAt } =
    gradiente;

  // Options for the carouse Slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(() => {
    const fetchGradiente = async () => await getSingleGradiente(id);
    fetchGradiente();
  }, []);

  useLayoutEffect(() => {
    return () => {
      setGradiente({});
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fd = new FormData();

    // If name is not modified, use the current post's title
    if (newName === "") fd.set("nombre", nombre);
    // If is modified
    else fd.set("nombre", newName);

    if (newLocation === "") fd.set("ubicacion", ubicacion);
    else fd.set("ubicacion", newLocation);

    if (newCapacity === "") fd.set("capacidad", capacidad);
    else fd.set("capacidad", newCapacity);

    if (newCosto === 0) fd.set("costo", costo);
    else fd.set("costo", newCosto);

    if (newProveedor === "") fd.set("proveedor", proveedor);
    else fd.set("proveedor", newProveedor);

    if (newPhotos !== "") {
      Array.from(newPhotos).forEach((photo) => fd.append("images", photo));
    } else {
      fotos.forEach((photo) => fd.append("images", photo));
    }

    try {
      if (user.role === "admin") await updateGradienteAdmin(fd, id, restore);
      else await updateGradiente(fd, id);
      toast.info(`Quiebragradientes actualizado exitosamente`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error)
      toast.error(`Ha ocurrido un error al actualizar quiebragradientes`, {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (
      window.confirm(
        "Se eliminará de la base de datos el registro del quiebragradientes y las fotos asociadas. Esta acción no se puede revertir.\n ¿Seguro que desea continuar?"
      )
    ) {
      try {
        await deleteGradiente(id, forceDelete);
        toast.info(`Quiebragradientes eliminado`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log(error)
        toast.error(`Ha ocurrido un error al eliminar quiebragradientes`, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
      }
    }
  };

  const handleDeleteChange = () => {
    setForceDelete((prev) => !prev);
  };
  const handleRestoreChange = () => {
    setRestore((prev) => !prev);
  };

  return (
    <div className='rounded-lg shadow-lg card bg-base-100 overflow-y-scroll'>
      <div className='card-body '>
        <h2 className='text-2xl my-4 font-bold card-title'>
          <button
            onClick={() => navigate(-1)}
            className='btn btn-outline btn-accent btn-sm hover:text-white'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 '
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z'
                clipRule='evenodd'
              />
            </svg>
          </button>
          Editar quiebragradientes
        </h2>

        <div className='flex justify-center text-lg'>
          <form className='w-full max-w-4xl' onSubmit={handleSubmit}>
            <div className='flex'>
              <div className='flex-1 w-1/2'>
                <div className='w-full px-3'>
                  <label
                    className='block tracking-wide text-xs font-bold mb-2'
                    htmlFor='grid-name'
                  >
                    Nombre *
                  </label>
                  <input
                    className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                    id='grid-name'
                    type='text'
                    required
                    name='name'
                    placeholder='Ingresar nombre del tanque'
                    defaultValue={nombre}
                    onChange={(e) => setNewName(e.target.value)}
                  />
                </div>
                <div className='w-full px-3'>
                  <label
                    className='block tracking-wide  text-xs font-bold mb-2'
                    htmlFor='grid-location'
                  >
                    Ubicación *
                  </label>
                  <input
                    className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                    id='grid-location'
                    type='text'
                    required
                    name='location'
                    placeholder='Ingresar descripción del tanque'
                    defaultValue={ubicacion}
                    onChange={(e) => setNewLocation(e.target.value)}
                  />
                </div>
                <div className='w-full px-3'>
                  <label
                    className='block tracking-wide text-xs font-bold mb-2'
                    htmlFor='grid-capacity'
                  >
                    Capacidad en m³ *
                  </label>
                  <input
                    className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                    id='grid-capacity'
                    type='text'
                    required
                    name='capacity'
                    placeholder='Ingresar capacidad del gradiente'
                    defaultValue={capacidad}
                    onChange={(e) => setNewCapacity(e.target.value)}
                  />
                </div>
                <div className='w-full px-3'>
                  <label
                    className='block tracking-wide text-xs font-bold mb-2'
                    htmlFor='grid-costo'
                  >
                    Costo *
                  </label>
                  <input
                    className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                    id='grid-costo'
                    type='text'
                    required
                    name='costo'
                    placeholder='Ingresar costo'
                    defaultValue={costo}
                    onChange={(e) => setNewCosto(e.target.value)}
                  />
                </div>
                <div className='w-full px-3'>
                  <label
                    className='block tracking-wide  text-xs font-bold mb-2'
                    htmlFor='grid-proveedor'
                  >
                    Proveedor *
                  </label>
                  <input
                    className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                    id='grid-proveedor'
                    type='text'
                    required
                    name='proveedor'
                    placeholder='Ingresar proveedor'
                    defaultValue={proveedor}
                    onChange={(e) => setNewProveedor(e.target.value)}
                  />
                </div>
                <div className='w-full px-3 flex gap-2 mt-4'>
                  {user && user.role === "admin" && (
                    <>
                      <input
                        type='checkbox'
                        id='force'
                        className='h-5 w-5'
                        onChange={handleDeleteChange}
                      />
                      <label
                        htmlFor='force'
                        className='cursor-pointer tracking-wide  text-xs font-bold mb-2'
                      >
                        &nbsp;Borrar permanentemente
                      </label>

                      {deletedAt && (
                        <>
                          <input
                            type='checkbox'
                            id='restaurar'
                            className='h-5 w-5'
                            onChange={handleRestoreChange}
                          />
                          <label
                            htmlFor='restaurar'
                            className='cursor-pointer tracking-wide  text-xs font-bold mb-2'
                          >
                            &nbsp;Restaurar
                          </label>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className='flex-1 w-1/2'>
                <div className='px-3 '>
                  <label
                    className='block inline tracking-wide  text-xs font-bold mb-2 '
                    htmlFor='grid-photos'
                  >
                    Fotos *
                  </label>
                  <div className='photos-container  p-2 mb-12 '>
                    <Slider {...settings}>
                      {!isLoading && fotos ? (
                        fotos.map((photo, photoIndex) => {
                          return (
                            <GradientePhotos photo={photo} key={photoIndex} />
                          );
                        })
                      ) : (
                        <Spinner />
                      )}
                    </Slider>
                  </div>
                  <div className='form-control'>
                    <input
                      className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                      id='grid-photos'
                      type='file'
                      multiple
                      onChange={(e) => setNewPhotos(e.target.files)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <small className='ml-1 text-2xs text-gray-500'>
              * Campos requeridos
            </small>
            <div className='divider'></div>

            <div className='card-actions justify-start'>
              <button
                className={`btn btn-success hover:bg-green-700 capitalize ${
                  isLoading ? "loading" : ""
                }`}
                type='submit'
              >
                {!isLoading ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                  >
                    <path d='M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z' />
                  </svg>
                ) : (
                  <></>
                )}
                &nbsp;Guardar cambios
              </button>
              <button
                className='btn btn-error hover:bg-red-700 capitalize'
                type='button'
                onClick={handleDelete}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z'
                    clipRule='evenodd'
                  />
                </svg>
                &nbsp;Borrar gradiente
              </button>
              <button
                type='button'
                className='btn btn-ghost ml-2 justify-self-end'
                onClick={openModal}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z'
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <div>
          <p className='font-light text-lg'>Consideraciones</p>
          <ul className='list-disc'>
            <li className='font-light text-sm my-1'>
              Los campos requeridos están marcados con un asterisco (*).
            </li>
            <li className='font-light text-sm my-1'>
              La ubicación debe tener más de 3 y menos de 100 caracteres.
            </li>
            <li className='font-light text-sm my-1'>
              La capacidad y el costo deben ser números enteros positivos.
            </li>
            <li className='font-light text-sm my-1'>
              El proveedor debe tener más de 3 y menos de 100 caracteres.
            </li>
            <li className='font-light text-sm my-1'>
              Las fotos deben ser en formato png, jpg o jpeg.
            </li>
            <li className='font-light text-sm my-1'>
              Cada foto no puede pesar más de 3MB.
            </li>
            <li className='font-light text-sm my-1'>
              Para agregar más de una foto mantenga presionada la tecla{" "}
              <kbd className='px-1'>Ctrl</kbd> en el teclado mientras selecciona
              varias fotos.
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default GradienteItem;
