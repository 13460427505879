import { useContext, useEffect } from "react";
import { Outlet, NavLink } from "react-router-dom";
import UserLogin from "../users/UserLogin";
import LoginContext from "../../context/login/LoginContext";

function Dashboard() {
  const { user } = useContext(LoginContext);

  useEffect(() => {
    if (!user) {
      return <UserLogin />;
    }
  }, [user]);

  return (
    <>
      <div className='relative max-h-max w-full bg-slate-900'>
        <div className='tabs'>
          <NavLink
            to='general'
            className={({ isActive }) =>
              isActive ? "tab tab-lifted tab-active" : "tab tab-lifted"
            }
          >
            General
          </NavLink>
          <NavLink
            to='juntaDirectiva'
            className={({ isActive }) =>
              isActive ? "tab tab-lifted tab-active" : "tab tab-lifted"
            }
          >
            Junta Directiva
          </NavLink>
          <NavLink
            to='imagenes'
            className={({ isActive }) =>
              isActive ? "tab tab-lifted tab-active" : "tab tab-lifted"
            }
          >
            Imágenes de la ASADA
          </NavLink>
          <NavLink
            to='contacto'
            className={({ isActive }) =>
              isActive ? "tab tab-lifted tab-active" : "tab tab-lifted"
            }
          >
            Contacto
          </NavLink>
        </div>
        <div className='rounded-lg rounded-tl-none p-7 shadow-lg card bg-base-100 border-t-none '>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
