import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useContext, useEffect } from "react";
import AsadaContext from "../../context/asada/asadaContext";

function ClientLayout() {
  const { asada, getSingleAsada } = useContext(AsadaContext);

  useEffect(() => {
    const fetchSingleAsada = async (asadaId) => {
      if (Object.keys(asada).length === 0) await getSingleAsada(asadaId);
    };
    fetchSingleAsada(process.env.REACT_APP_ASADA_ID);
  }, []);

  return (
    <>
      <Navbar asada={asada} />
      <main className='mx-auto'>
        <Outlet asada={asada} />
      </main>
      <Footer asada={asada} />
    </>
  );
}

export default ClientLayout;
