import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TransparenciaContext from "../../context/transparencia/TransparenciaContext";
import "moment/locale/es";
import Modal from "../shared/Modal";
import LoginContext from "../../context/login/LoginContext";

function TransparenciaAdd() {
  const { isLoading, setIsLoading, createTransparencia } =
      useContext(TransparenciaContext),
    navigate = useNavigate(),
    [dname, setDname] = useState(""),
    [ddesc, setDdesc] = useState(""),
    [ddoc, setDdoc] = useState(""),
    [btnDisabled, setBtnDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useContext(LoginContext);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    if (dname.length !== 0 && ddoc.length !== 0) setBtnDisabled(false);
    else setBtnDisabled(true);

    setIsLoading(false);
  }, [dname, ddesc, ddoc]);

  const onNameChange = (e) => {
      setDname(e.target.value);
    },
    onDescChange = (e) => {
      setDdesc(e.target.value);
    },
    onFileChange = (e) => {
      setDdoc(e.target.files[0]);
    },
    formReset = () => {
      setDname("");
      setDdesc("");
      setDdoc("");
    },
    onSubmit = async (e) => {
      e.preventDefault();
      const fd = new FormData();
      fd.set("nombre", dname);
      fd.set("descripcion", ddesc);
      fd.append("file", ddoc);

      const searchParams = new URLSearchParams(window.location.search);
      const asadaId = searchParams.get("aid");
      if (asadaId) await createTransparencia(fd, asadaId);
      else createTransparencia(fd, user.asadaId);

      formReset();
    };

  return (
    <>
      <h2 className='text-2xl my-4 font-bold card-title'>
        <button
          onClick={() => navigate(-1)}
          className='btn btn-outline btn-accent btn-sm hover:text-white'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-6 w-6 '
            viewBox='0 0 20 20'
            fill='currentColor'
          >
            <path
              fillRule='evenodd'
              d='M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z'
              clipRule='evenodd'
            />
          </svg>
        </button>
        Agregar nuevo documento de transparencia
      </h2>
      <div className='text-lg flex justify-center'>
        <form className='w-full max-w-lg' onSubmit={onSubmit}>
          <div className='w-full px-3'>
            <label
              className='block  tracking-wide  text-xs font-bold mb-2'
              htmlFor='grid-name'
            >
              Nombre *
            </label>
            <input
              className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              id='grid-fname'
              type='text'
              required
              name='fname'
              placeholder='Nombre del documento'
              onChange={onNameChange}
              value={dname}
            />
          </div>
          <div className='w-full px-3'>
            <label
              className='block  tracking-wide text-xs font-bold mb-2'
              htmlFor='grid-fdesc'
            >
              Descripción
            </label>
            <textarea
              className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              id='grid-desc'
              type='text'
              name='fdesc'
              placeholder='Descripción breve del documento'
              onChange={onDescChange}
              value={ddesc}
            />
          </div>
          <div className='w-full px-3'>
            <label
              className='block  tracking-wide text-xs font-bold mb-2'
              htmlFor='grid-email'
            >
              Documento *
            </label>
            <input
              className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              id='grid-fdoc'
              type='file'
              lang='es'
              name='fdoc'
              onChange={onFileChange}
            />
            <small className='ml-1 text-2xs text-gray-500'>
              Formato permitido: .pdf
            </small>{" "}
            <br />
            <small className='ml-1 text-2xs text-gray-500'>
              * Campos requeridos
            </small>
          </div>

          <div className='divider'></div>

          <div className='card-actions justify-start'>
            <button
              type='submit'
              className={`btn btn-success hover:bg-green-700 capitalize ${
                isLoading ? "loading" : ""
              }`}
              disabled={btnDisabled}
            >
              {!isLoading ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path d='M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z' />
                </svg>
              ) : (
                <></>
              )}
              &nbsp;Agregar
            </button>
            <button
              type='button'
              className='btn btn-ghost ml-2 justify-self-end'
              onClick={openModal}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z'
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <div>
          <p className='font-light text-lg'>Consideraciones</p>
          <ul className='list-disc'>
            <li className='font-light text-sm my-1'>
              Los campos requeridos están marcados con un asterisco (*).
            </li>
            <li className='font-light text-sm my-1'>
              El nombre debe tener más de 3 y menos de 70 caracteres.
            </li>
            <li className='font-light text-sm my-1'>
              La descripción debe tener menos de 100 caracteres.
            </li>
            <li className='font-light text-sm my-1'>
              El documento estará disponible en la página pública, en la pestaña
              'Documentación'. Desde aquí se puede descargar.
            </li>
            <li className='font-light text-sm my-1'>
              El documento debe ser en formato pdf.
            </li>
            <li className='font-light text-sm my-1'>
              El documento no debe pesar más de 5MB.
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
}

export default TransparenciaAdd;
