import { useEffect, useState, useContext, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import asadaContext from "../../../context/asada/asadaContext";
import BancoProcesadorContext from "../../../context/bancoProcesadorContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; //React-Toastify CSS
import Modal from "../../shared/Modal";

function AsadaConfiguraciones() {
  const {
    asada,
    updateAsadaAdmin,
    isLoading,
    getSingleAsada,
    setAsada,
    createMetodoDePagoAsada,
    deleteMetodoDePagoAsada,
  } = useContext(asadaContext);
  const { bancos, getAllBancosProcesadores } = useContext(BancoProcesadorContext);
  const params = useParams();
  const { id } = params;

  // Pago con SINPE
  const [newApiToken, setNewApiToken] = useState("");
  const [newApiKeyEstablishment, setNewApiKeyEstablishment] = useState("");

  // Pago con tarjeta
  const [newTarjetaLogin, setNewTarjetaLogin] = useState("");
  const [newTarjetaSecretKey, setNewTarjetaSecretKey] = useState("");

  // Google Maps
  const [newGoogleMapsApiKey, setNewGoogleMapsApiKey] = useState("");
  const [newGoogleMapsLatitude, setNewGoogleMapsLatitude] = useState("");
  const [newGoogleMapsLongitude, setNewGoogleMapsLongitude] = useState("");

  // Banco procesador de pagos
  const [bancoSeleccionado, setBancoSeleccionado] = useState("");

  const {
    nombre,
    bancoProcesadorId,
    apiToken,
    apiKeyEstablishment,
    tarjetaLogin,
    tarjetaSecretKey,
    googleMapsApiKey,
    googleMapsLatitude,
    googleMapsLongitude,
    metodoPagos,
  } = asada;

  const metodoPagosIds = Array.isArray(metodoPagos) ? metodoPagos.map((metodo) => metodo.id) : [];

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const updateAsadaMetodoDePago = async (metodoPagoId) => {
    if (metodoPagosIds.includes(metodoPagoId)) {
      try {
        await deleteMetodoDePagoAsada(id, metodoPagoId); // Borrar método de pago si ya existe en el array
        toast.info(`Método de pago eliminado de la Asada`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Ha ocurrido un error al eliminar el método de pago", error);
        toast.error(`Ha ocurrido un error al eliminar el método de pago`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      try {
        await createMetodoDePagoAsada(id, metodoPagoId); // Agregar método de pago no existe
        toast.info(`Método de pago agregado a la Asada`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Ha ocurrido un error al crear el método de pago", error);
        toast.error(`Ha ocurrido un error al crear el método de pago`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    await getSingleAsada(id); // Cargar asada
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = {
        apiToken: newApiToken,
        apiKeyEstablishment: newApiKeyEstablishment,
        tarjetaLogin: newTarjetaLogin,
        tarjetaSecretKey: newTarjetaSecretKey,
        googleMapsApiKey: newGoogleMapsApiKey,
        googleMapsLatitude: newGoogleMapsLatitude,
        googleMapsLongitude: newGoogleMapsLongitude,
      };

      // if banco seleccionado is not empty, add it to the form
      if (bancoSeleccionado !== "") formData.bancoProcesadorId = bancoSeleccionado;

      await updateAsadaAdmin(formData, id);

      setTimeout(async () => {
        await getSingleAsada(id);
      }, 200);
    } catch (error) {
      console.error("Error al manejar el envío del formulario:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getSingleAsada(id);
        await getAllBancosProcesadores();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (
      newApiToken === "" ||
      newApiKeyEstablishment === "" ||
      newTarjetaLogin === "" ||
      newTarjetaSecretKey === "" ||
      newGoogleMapsApiKey === "" ||
      newGoogleMapsLatitude === "" ||
      newGoogleMapsLongitude === ""
    ) {
      setNewApiToken(apiToken);
      setNewApiKeyEstablishment(apiKeyEstablishment);
      setNewTarjetaLogin(tarjetaLogin);
      setNewTarjetaSecretKey(tarjetaSecretKey);
      setNewGoogleMapsApiKey(googleMapsApiKey);
      setNewGoogleMapsLatitude(googleMapsLatitude);
      setNewGoogleMapsLongitude(googleMapsLongitude);
    }
  }, [
    apiToken,
    apiKeyEstablishment,
    tarjetaLogin,
    tarjetaSecretKey,
    googleMapsApiKey,
    googleMapsLatitude,
    googleMapsLongitude,
  ]);

  useLayoutEffect(() => {
    return () => {
      setAsada({});
    };
  }, []);

  return (
    <div>
      <div className="card-body p-0">
        <h2 className="text-2xl my-4 font-bold card-title">
          <button
            onClick={() => navigate("/admin/asadas")}
            className="btn btn-outline btn-accent btn-sm hover:text-white"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 " viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          Editar Asada
        </h2>

        <div className="flex justify-center text-lg">
          <form className="w-full " onSubmit={handleSubmit}>
            <div className="space-y-3">
              <div className="flex-1 border-[1px] rounded-md p-3">
                <p className="font-thin mb-1">Seleccione los métodos de pago disponibles para la Asada: </p>
                <div className="form-control max-w-xs">
                  <label className="label cursor-pointer">
                    <span className="label-text">SINPE</span>
                    <input
                      type="checkbox"
                      className="checkbox checkbox-accent"
                      value={1}
                      onChange={() => updateAsadaMetodoDePago(1)}
                      checked={metodoPagosIds.includes(1)}
                    />
                  </label>
                  <label className="label cursor-pointer">
                    <span className="label-text">Tarjeta de crédito o débito</span>
                    <input
                      type="checkbox"
                      className="checkbox checkbox-accent"
                      value={2}
                      onChange={() => updateAsadaMetodoDePago(2)}
                      checked={metodoPagosIds.includes(2)}
                    />
                  </label>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row gap-3">
                <div className="flex-1 border-[1px] rounded-md p-3">
                  <h3 className="font-thin mb-3">Pago con SINPE</h3>
                  <div className="w-full px-3">
                    <label className="block tracking-wide text-xs font-bold mb-2" htmlFor="grid-apiToken">
                      API Token
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                      id="grid-apiToken"
                      type="text"
                      name="apiToken"
                      placeholder="Ingresar API token"
                      defaultValue={apiToken}
                      onChange={(e) => setNewApiToken(e.target.value)}
                    />
                  </div>
                  <div className="w-full px-3">
                    <label className="block tracking-wide text-xs font-bold mb-2" htmlFor="grid-apiKeyEstablishment">
                      API Key Establishment
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                      id="grid-apiKeyEstablishment"
                      type="text"
                      name="apiKeyEstablishment"
                      placeholder="Ingresar API key establishment"
                      defaultValue={apiKeyEstablishment}
                      onChange={(e) => setNewApiKeyEstablishment(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex-1 border-[1px] rounded-md p-3">
                  <h3 className="font-thin mb-3">Pago con tarjeta de crédito/débito</h3>
                  <div className="w-full px-3">
                    <label className="block tracking-wide text-xs font-bold mb-2" htmlFor="grid-login">
                      Login
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                      id="grid-login"
                      type="text"
                      name="login"
                      placeholder="Ingresar tarjeta login"
                      defaultValue={tarjetaLogin}
                      onChange={(e) => setNewTarjetaLogin(e.target.value)}
                    />
                  </div>
                  <div className="w-full px-3">
                    <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-secretKey">
                      Secret key
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                      id="grid-secretKey"
                      type="text"
                      name="secretKey"
                      placeholder="Ingresar tarjeta secret key"
                      defaultValue={tarjetaSecretKey}
                      onChange={(e) => setNewTarjetaSecretKey(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col lg:flex-row gap-3">
                <div className="flex-1 border-[1px] rounded-md p-3">
                  <h3 className="font-thin mb-3">Google Maps</h3>
                  <div className="w-full px-3">
                    <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-googleMapsApiKey">
                      Google Maps API Key
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                      id="grid-googleMapsApiKey"
                      type="text"
                      name="googleMapsApiKey"
                      placeholder="Ingresar API key de Google Maps"
                      defaultValue={googleMapsApiKey}
                      onChange={(e) => setNewGoogleMapsApiKey(e.target.value)}
                    />
                  </div>
                  <div className="w-full px-3">
                    <label className="block tracking-wide text-xs font-bold mb-2">Coordenadas</label>
                    <div className="flex gap-3">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                        id="grid-latitude"
                        type="text"
                        name="latitude"
                        placeholder="Ingresar latitud"
                        defaultValue={googleMapsLatitude}
                        onChange={(e) => setNewGoogleMapsLatitude(e.target.value)}
                      />
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm"
                        id="grid-longitude"
                        type="text"
                        name="longitude"
                        placeholder="Ingresar longitud"
                        defaultValue={googleMapsLongitude}
                        onChange={(e) => setNewGoogleMapsLongitude(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-1 border-[1px] rounded-md p-3">
                  <h3 className="font-thin mb-3">Banco procesador de pagos en línea</h3>
                  <div className="w-full px-3">
                    <p className="font-thin text-sm">
                      Banco actual:{" "}
                      <span className="font-bold">
                        {bancoProcesadorId && bancos
                          ? bancos.find((banco) => banco.id === bancoProcesadorId)?.nombre ?? "Sin banco"
                          : "Sin banco"}
                      </span>
                    </p>
                    <div className="divider my-1"></div>
                    <label className="block tracking-wide text-xs font-bold mb-2" htmlFor="grid-googleMapsApiKey">
                      Seleccione el banco para Asada {nombre}
                    </label>
                    <select
                      defaultValue="0"
                      className="select select-bordered w-full"
                      onChange={(e) => setBancoSeleccionado(e.target.value)}
                    >
                      <option disabled value="0">
                        -- Seleccionar Banco --
                      </option>
                      {bancos &&
                        bancos.map((banco) => (
                          <option value={banco.id} key={banco.id}>
                            {banco.nombre}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <small className="ml-1 text-2xs text-gray-500">* Campos requeridos</small>
            <div className="divider"></div>

            <div className="card-actions justify-start">
              <button
                className={`btn btn-success hover:bg-green-700 capitalize ${isLoading ? "loading" : ""}`}
                type="submit"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                </svg>
                &nbsp;Guardar cambios
              </button>
              <button type="button" className="btn btn-ghost ml-2" onClick={openModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <div>
          <p className="font-light text-lg">Consideraciones</p>
          <ul className="list-disc">
            <li className="font-light text-sm my-1">Los campos requeridos están marcados con un asterisco (*).</li>
            <li className="font-light text-sm my-1">
              Los campos 'Horario de Atención' y 'Dirección' se muestran en la página pública, en la pestaña 'Contacto'.
              Todos los demás campos se muestran en la pestaña de Inicio.
            </li>
            <li className="font-light text-sm my-1">
              Los campos 'Usuarios abastecidos', 'Tanques de Almacenamiento' y 'Quiebragradientes' deben ser números
              enteros mayores a 0.
            </li>
            <li className="font-light text-sm my-1">
              Para borrar un registro permanentemente de la base de datos marque la casilla 'Borrar permanentemente' y
              luego presione el botón 'Borrar ASADA'. Para hacer un 'soft-delete' desmarque la casilla.
            </li>
            <li className="font-light text-sm my-1">
              Para restaurar un registro que fue 'soft-deleted' marque la casilla 'Restaurar' y luego presione el botón
              'Guardar Cambios'. Esta casilla solamente aparece cuando un registro ha sido 'soft-deleted'.
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default AsadaConfiguraciones;
