import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PozosContext from "../../context/pozosContext";
import Modal from "../shared/Modal";
import LoginContext from "../../context/login/LoginContext";
import { toast } from "react-toastify";

function setInputFilter(textbox, inputFilter) {
  [
    "input",
    "keydown",
    "keyup",
    "mousedown",
    "mouseup",
    "select",
    "contextmenu",
    "drop",
  ].forEach(function (event) {
    textbox.addEventListener(event, function () {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty("oldValue")) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = "";
      }
    });
  });
}

function PozoAdd() {
  const { isLoading, setIsLoading, createPozo } = useContext(
    PozosContext
  );
  const { user } = useContext(LoginContext);

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [capacity, setCapacity] = useState("");
  const [costo, setCosto] = useState(0);
  const [proveedor, setProveedor] = useState("");
  const [photos, setPhotos] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    setInputFilter(document.getElementById("grid-capacity"), function (value) {
      return /^[0-9]*\.?[0-9]*$/.test(value); // allow digits and only 1 dot
    });
    setInputFilter(document.getElementById("grid-costo"), function (value) {
      return /^[0-9]*\.?[0-9]*$/.test(value); // allow digits and only 1 dot
    });
    setIsLoading(false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fd = new FormData();
    fd.set("nombre", name);
    fd.set("ubicacion", location);
    fd.set("capacidad", capacity);
    fd.set("costo", costo);
    fd.set("proveedor", proveedor);

    photos.forEach((photo) => {
      fd.append("images", photo);
    });

    const searchParams = new URLSearchParams(window.location.search);
    const asadaId = searchParams.get("aid");
    try {
      if (asadaId) await createPozo(fd, asadaId);
      else createPozo(fd, user.asadaId);
      toast.success(`Pozo creado exitosamente`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error)
      toast.error(`Ha ocurrido un error al crear pozo`, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className='rounded-lg shadow-lg card bg-base-100'>
      <div className='card-body'>
        <h2 className='text-2xl my-4 font-bold card-title'>
          <button
            onClick={() => navigate(-1)}
            className='btn btn-outline btn-accent btn-sm hover:text-white'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 '
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z'
                clipRule='evenodd'
              />
            </svg>
          </button>
          Agregar pozo
        </h2>
        <div className='flex justify-center text-lg'>
          <form className='w-full max-w-lg' onSubmit={handleSubmit}>
            <div className='w-full px-3'>
              <label
                className='block tracking-wide text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Nombre *
              </label>
              <input
                className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-name'
                type='text'
                required
                name='name'
                placeholder='Nombre del pozo'
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className='w-full px-3'>
              <label
                className='block tracking-wide text-xs font-bold mb-2'
                htmlFor='grid-location'
              >
                Ubicación *
              </label>
              <input
                className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-location'
                type='text'
                required
                name='location'
                placeholder='Ubicación del pozo'
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            <div className='w-full px-3'>
              <label
                className='block tracking-wide text-xs font-bold mb-2'
                htmlFor='grid-capacity'
              >
                Capacidad en metros cúbicos (m³) *
              </label>
              <input
                className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-capacity'
                type='text'
                required
                name='capacity'
                placeholder='Ingresar m³ (Ej: 300.25)'
                onChange={(e) => setCapacity(e.target.value)}
              />
            </div>
            <div className='w-full px-3'>
              <label
                className='block tracking-wide text-xs font-bold mb-2'
                htmlFor='grid-costo'
              >
                Costo *
              </label>
              <input
                className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-costo'
                type='text'
                required
                name='proveedor'
                placeholder='Ingresar costo en colones'
                onChange={(e) => setCosto(e.target.value)}
              />
            </div>
            <div className='w-full px-3'>
              <label
                className='block tracking-wide text-xs font-bold mb-2'
                htmlFor='grid-proveedor'
              >
                Proveedor *
              </label>
              <input
                className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-proveedor'
                type='text'
                required
                name=' proveedor'
                placeholder='Ingresar proveedor'
                onChange={(e) => setProveedor(e.target.value)}
              />
            </div>

            <div className='w-full px-3'>
              <label
                className='block tracking-wide text-xs font-bold mb-2'
                htmlFor='grid-photos'
              >
                Fotos *
              </label>
              <input
                className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-photos'
                type='file'
                multiple
                lang='es'
                name='photos'
                onChange={(e) => setPhotos(Array.from(e.target.files))}
              />
              <small className='ml-1 text-2xs text-gray-500'>
                Formato permitido: .jpg, .jpeg, .png
              </small>
              <br />
              <small className='ml-1 text-2xs text-gray-500'>
                * Campos requeridos
              </small>
            </div>
            <br />

            <div className='divider'></div>

            <div className='card-actions justify-start'>
              <button
                type='submit'
                className={`btn btn-success hover:bg-green-700 capitalize ${
                  isLoading ? "loading" : ""
                }`}
              >
                {!isLoading ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                  >
                    <path d='M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z' />
                  </svg>
                ) : (
                  <></>
                )}
                &nbsp;Agregar
              </button>
              <button
                type='button'
                className='btn btn-ghost ml-2 justify-self-end'
                onClick={openModal}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z'
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <div>
          <p className='font-light text-lg'>Consideraciones</p>
          <ul className='list-disc'>
            <li className='font-light text-sm my-1'>
              Los campos requeridos están marcados con un asterisco (*).
            </li>
            <li className='font-light text-sm my-1'>
              La ubicación debe tener más de 3 y menos de 100 caracteres.
            </li>
            <li className='font-light text-sm my-1'>
              La capacidad y el costo deben ser números enteros positivos.
            </li>
            <li className='font-light text-sm my-1'>
              El proveedor debe tener más de 3 y menos de 100 caracteres.
            </li>
            <li className='font-light text-sm my-1'>
              Las fotos deben ser en formato png, jpg o jpeg.
            </li>
            <li className='font-light text-sm my-1'>
              Cada foto no puede pesar más de 3MB.
            </li>
            <li className='font-light text-sm my-1'>
              Para agregar más de una foto mantenga presionada la tecla{" "}
              <kbd className='px-1'>Ctrl</kbd> en el teclado mientras selecciona
              varias fotos.
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default PozoAdd;
