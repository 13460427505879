import { Link } from "react-router-dom";
import Spinner from "../../shared/Spinner";
import { useEffect, useContext, useState } from "react";
import ProjectContext from "../../../context/projects/ProjectContext";
import AsadaContext from "../../../context/asada/asadaContext";
import LoginContext from "../../../context/login/LoginContext";
import InputSearchAsada from "../../shared/InputSearchAsada";
import ProyectoTableAdmin from "./ProyectoTableAdmin";
import Modal from "../../shared/Modal";

function ProyectoListAdmin() {
  const { isLoading, proyectos, getAllProyectosPrivate } =
    useContext(ProjectContext);
  const { asadas, getAllAsadas, setAsada, asada } = useContext(AsadaContext);
  const { user } = useContext(LoginContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };
  useEffect(() => {
    const fetchAsadas = async () => await getAllAsadas();
    fetchAsadas();
  }, []);

  useEffect(() => {
    const fetchProyectos = async () => await getAllProyectosPrivate(asada.id);
    fetchProyectos();
  }, [asada]);

  return (
    <div className='rounded-lg  bg-base-100'>
      {user && user.role === "admin" ? (
        <div className='card-body'>
          <h2 className='text-2xl my-4 font-bold card-title'>
            Lista de Proyectos{" "}
            <button
              type='button'
              className='btn btn-ghost ml-2 justify-self-end'
              onClick={openModal}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z'
                />
              </svg>
            </button>
          </h2>
          {asadas && (
            <div className='flex flex-col items-center justify-center w-full '>
              <p className='font-light text-lg'>Seleccionar ASADA</p>
              <InputSearchAsada options={asadas} setAsada={setAsada} />
            </div>
          )}

          <>
            {asada && Object.keys(asada).length !== 0 && (
              <>
                <div className='form-action-btns h-10 w-full flex justify-end'></div>

                <div className='overflow-x-auto flex flex-col items-center justify-center'>
                  <h3 className='mb-3'>{asada.nombre}</h3>
                  <Link
                    to={`add?aid=${asada.id}`}
                    className={`btn btn-accent btn-sm capitalize mb-5`}
                  >
                    Agregar Proyecto &nbsp;
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-5 w-5'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                    >
                      <path
                        fillRule='evenodd'
                        d='M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V8z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </Link>
                  <div className='overflow-x-auto flex justify-center w-full'>
                    <div className='w-full'>
                      {!isLoading ? (
                        <ProyectoTableAdmin proyectos={proyectos} />
                      ) : (
                        <Spinner />
                      )}{" "}
                    </div>
                  </div>
                </div>
                <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
                  <div>
                    <p className='font-light text-lg'>Consideraciones</p>
                    <ul className='list-disc'>
                      <li className='font-light text-sm my-1'>
                        Los datos de la tabla se ordenan alfabéticamente al
                        hacer clic en el nombre de la columna.
                      </li>
                      <li className='font-light text-sm my-1'>
                        Los botones 'Anterior' y 'Siguiente' se activan cuando
                        hay más de 10 proyectos.
                      </li>
                      <li className='font-light text-sm my-1'>
                        La fecha de borrado indica si un registro ha sido
                        'soft-deleted' de la base de datos.
                      </li>
                    </ul>
                  </div>
                </Modal>
              </>
            )}
          </>
        </div>
      ) : (
        <h1>Usuario no autorizado</h1>
      )}
    </div>
  );
}

export default ProyectoListAdmin;
