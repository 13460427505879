import { useEffect, useContext, useState, useLayoutEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import JobContext from "../../context/jobs/JobContext";
import Spinner from "../shared/Spinner";
import { toast } from "react-toastify";
import LoginContext from "../../context/login/LoginContext";
import Modal from "../shared/Modal";

function JobItem() {
  const {
    setJob,
    isLoading,
    displayCurriculum,
    solicitud,
    getSingleSolicitud,
    updateSolicitud,
    deleteSolicitud,
    updateSolicitudAdmin,
  } = useContext(JobContext);
  const { user } = useContext(LoginContext);

  const [estado, setEstado] = useState(true);

  const [forceDelete, setForceDelete] = useState(false);
  const [restore, setRestore] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const {
    nombre,
    apellidos,
    correo,
    telefono,
    curriculum,
    is_pendiente,
    deletedAt,
  } = solicitud;

  useEffect(() => {
    const fetchSolicitud = async () => {
      await getSingleSolicitud(id);
    };
    fetchSolicitud();
  }, []);

  useLayoutEffect(() => {
    return () => {
      setJob({});
    };
  }, []);

  const displayCV = async (e) => {
    e.preventDefault();
    await displayCurriculum(id);
  };

  const onChange = (e) => {
    setEstado(e.target.value);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (
      window.confirm(
        "Se eliminará de la base de datos el registro de la solicitud de empleo. Esta acción no se puede revertir.\n ¿Seguro que desea continuar?"
      )
    ) {
      await deleteSolicitud(id, forceDelete);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    solicitud.is_pendiente = estado;

    if (user.role === "admin")
      await updateSolicitudAdmin(solicitud, id, restore);
    else updateSolicitud(solicitud, id);
  };

  const handleDeleteChange = () => {
    setForceDelete((prev) => !prev);
  };
  const handleRestoreChange = () => {
    setRestore((prev) => !prev);
  };

  return (
    <div className='rounded-lg shadow-lg card bg-base-100'>
      <div className='card-body'>
        <h2 className='text-2xl my-4 font-bold card-title'>
          <button
            onClick={() => navigate(-1)}
            className='btn btn-outline btn-accent btn-sm hover:text-white'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 '
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z'
                clipRule='evenodd'
              />
            </svg>
          </button>
          Información de Solicitud de Empleo
        </h2>

        <div className='flex justify-center text-lg'>
          <form className='w-full max-w-lg' onSubmit={onSubmit}>
            <div className='w-full px-3'>
              <label
                className='block tracking-wide  text-xs font-bold mb-2'
                htmlFor='grid-nombre'
              >
                Nombre
              </label>
              <input
                className='appearance-none cursor-not-allowed block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-nombre'
                type='text'
                readOnly
                name='nombre'
                placeholder='Nombre del formulario'
                defaultValue={nombre}
              />
            </div>
            <div className='w-full px-3'>
              <label
                className='block tracking-wide  text-xs font-bold mb-2'
                htmlFor='grid-apellidos'
              >
                Apellidos
              </label>
              <input
                className='appearance-none cursor-not-allowed  block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-apellidos'
                type='text'
                readOnly
                name='apellidos'
                placeholder='Descripción breve del formulario'
                defaultValue={apellidos}
              />
            </div>
            <div className='w-full px-3'>
              <label
                className='block tracking-wide  text-xs font-bold mb-2'
                htmlFor='grid-correo'
              >
                Correo electrónico
              </label>
              <input
                className='appearance-none cursor-not-allowed  block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-correo'
                type='text'
                readOnly
                name='correo'
                placeholder='correo@ejemplo.com'
                defaultValue={correo}
              />
            </div>
            <div className='w-full px-3'>
              <label
                className='block tracking-wide text-xs font-bold mb-2'
                htmlFor='grid-telefono'
              >
                Teléfono
              </label>
              <input
                className='appearance-none cursor-not-allowed  block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-telefono'
                type='text'
                readOnly
                name='telefono'
                placeholder='Número de teléfono'
                defaultValue={telefono}
              />
            </div>
            <div className='w-full px-3'>
              <label
                className='block tracking-wide text-xs font-bold mb-2'
                htmlFor='grid-fdoc'
              >
                Curriculum: {curriculum ? curriculum.split("/")[5] : ""}
              </label>
              <button
                className='btn btn-accent btn-xs capitalize'
                onClick={displayCV}
              >
                Ver&nbsp;
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-4 w-4'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path d='M10 12a2 2 0 100-4 2 2 0 000 4z' />
                  <path
                    fillRule='evenodd'
                    d='M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z'
                    clipRule='evenodd'
                  />
                </svg>
              </button>
            </div>
            <div className='w-full px-3 mt-3'>
              <label
                className='block tracking-wide text-xs font-bold mb-2'
                htmlFor='grid-message'
              >
                {`Estado actual: ${is_pendiente ? "Pendiente" : "Revisado"}`}
              </label>

              <div className='form-control'>
                <select
                  className='select select-accent w-full max-w-xs'
                  onChange={onChange}
                  value={estado}
                >
                  <option value={true}>Pendiente</option>
                  <option value={false}>Revisado</option>
                </select>
              </div>
            </div>
            <div className='w-full px-3 flex gap-2 mt-4'>
              {user && user.role === "admin" && (
                <>
                  <input
                    type='checkbox'
                    id='force'
                    className='h-5 w-5'
                    onChange={handleDeleteChange}
                  />
                  <label
                    htmlFor='force'
                    className='cursor-pointer tracking-wide  text-xs font-bold mb-2'
                  >
                    &nbsp;Borrar permanentemente
                  </label>

                  {deletedAt && (
                    <>
                      <input
                        type='checkbox'
                        id='restaurar'
                        className='h-5 w-5'
                        onChange={handleRestoreChange}
                      />
                      <label
                        htmlFor='restaurar'
                        className='cursor-pointer tracking-wide  text-xs font-bold mb-2'
                      >
                        &nbsp;Restaurar
                      </label>
                    </>
                  )}
                </>
              )}
            </div>

            <div className='divider'></div>

            <div className='card-actions justify-start'>
              <button
                type='submit'
                className={`btn btn-success hover:bg-green-700 capitalize ${
                  isLoading ? "loading" : ""
                }`}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path d='M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z' />
                </svg>
                &nbsp;Guardar cambios
              </button>
              <button
                className='btn btn-error hover:bg-red-700 capitalize'
                type='button'
                onClick={handleDelete}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z'
                    clipRule='evenodd'
                  />
                </svg>
                &nbsp;Borrar Solicitud
              </button>
              <button
                type='button'
                className='btn btn-ghost ml-2 justify-self-end'
                onClick={openModal}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z'
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <div>
          <p className='font-light text-lg'>Consideraciones</p>
          <ul className='list-disc'>
            <li className='font-light text-sm my-1'>
              El campo 'Estado actual' es el único que se puede editar en una
              solicitud de empleo.
            </li>
            <li className='font-light text-sm my-1'>
              El curriculum se descarga desde la pestaña que se abre al hacer
              clic en el botón 'Ver'.
            </li>
            {user && user.role === "admin" && (
              <>
                <li className='font-light text-sm my-1'>
                  Para borrar un registro permanentemente de la base de datos
                  marque la casilla 'Borrar permanentemente' y luego presione el
                  botón 'Borrar Solicitud'. Para hacer un 'soft-delete'
                  desmarque la casilla.
                </li>
                <li className='font-light text-sm my-1'>
                  Para restaurar un registro que fue 'soft-deleted' marque la
                  casilla 'Restaurar' y luego presione el botón 'Guardar
                  Cambios'. Esta casilla solamente aparece cuando un registro ha
                  sido 'soft-deleted'.
                </li>
              </>
            )}
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default JobItem;
