import { useEffect, useState, useContext, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import asadaContext from "../../../context/asada/asadaContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; //React-Toastify CSS
import Modal from "../../shared/Modal";

function AsadaItem() {
  const { asada, updateAsadaAdmin, deleteAsadaAdmin, isLoading, getSingleAsada, setAsada } = useContext(asadaContext);
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const [newNombre, setNewNombre] = useState("");
  const [newSlogan, setNewSlogan] = useState("");
  const [newHistoria, setNewHistoria] = useState("");
  const [newNumEmpresaCisa, setNewNumEmpresaCisa] = useState("");
  const [newCedulaJuridica, setNewCedulaJuridica] = useState("");
  const [newMission, setNewMission] = useState("");
  const [newVision, setNewVision] = useState("");
  const [newSchedule, setNewSchedule] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [newTextoMetodosPago, setNewTextoMetodosPago] = useState("");
  const [newTextoProyectos, setNewTextoProyectos] = useState("");
  const [newExtension, setNewExtension] = useState("");
  const [newUsers, setNewUsers] = useState("");
  const [newTanks, setNewTanks] = useState("");
  const [newGradientes, setNewGradientes] = useState("");
  const [newPozos, setNewPozos] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [forceDelete, setForceDelete] = useState(false);
  const [restore, setRestore] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const {
    nombre,
    num_empresa_cisa,
    cedulaJuridica,
    mision,
    vision,
    direccion,
    horario,
    texto_proyectos,
    texto_metodos_pago,
    usuarios,
    tanques,
    extension,
    gradientes,
    deletedAt,
    slogan,
    historia,
    pozos
  } = asada;

  useEffect(() => {
    const fetchSingleAsada = async (asadaId) => {
      await getSingleAsada(asadaId);
    };
    fetchSingleAsada(id);
  }, []);

  useLayoutEffect(() => {
    return () => {
      setAsada({});
    };
  }, []);

  useEffect(() => {
    if (
      newNombre === "" ||
      newNumEmpresaCisa === "" ||
      newCedulaJuridica === "" ||
      newMission === "" ||
      newVision === "" ||
      newSchedule === "" ||
      newExtension === "" ||
      newAddress === "" ||
      newUsers === 0 ||
      newTanks === 0 ||
      newGradientes === 0 ||
      newPozos === 0 ||
      newTextoMetodosPago === "" ||
      newTextoProyectos === "" ||
      newSlogan === "" ||
      newHistoria === ""
    ) {
      setNewNombre(nombre);
      setNewNumEmpresaCisa(num_empresa_cisa);
      setNewCedulaJuridica(cedulaJuridica);
      setNewMission(mision);
      setNewVision(vision);
      setNewSchedule(horario);
      setNewExtension(extension);
      setNewAddress(direccion);
      setNewUsers(usuarios);
      setNewTanks(tanques);
      setNewGradientes(gradientes);
      setNewPozos(pozos);
      setNewTextoMetodosPago(texto_metodos_pago);
      setNewTextoProyectos(texto_proyectos);
      setNewSlogan(slogan);
      setNewHistoria(historia);
    }
  }, [
    nombre,
    num_empresa_cisa,
    cedulaJuridica,
    newMission,
    newVision,
    newSchedule,
    newExtension,
    newAddress,
    newUsers,
    newTanks,
    newGradientes,
    newPozos,
    texto_metodos_pago,
    texto_proyectos,
    slogan,
    historia,
  ]);

  const checkFormValidation = (form) => {
    const { nombre, num_empresa_cisa, usuarios, tanques, gradientes, pozos } = form;

    if (nombre) {
      if (nombre.length < 3 || nombre.length > 100) {
        toast.error(
          `Ha ocurrido un error al editar la ASADA: "La longitud del campo 'Nombre' debe ser mayor a 3 y menor a 100 caracteres."`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return false;
      }
    }

    if (num_empresa_cisa) {
      const numEmpresaAsNum = parseInt(num_empresa_cisa, 10);
      if (!Number.isInteger(numEmpresaAsNum) || numEmpresaAsNum < 0) {
        toast.error(
          `Ha ocurrido un error al editar la ASADA: "El campo 'Número de Empresa CISA' debe ser un número entero positivo.'"`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return false;
      }
    }
    if (usuarios) {
      if (!Number.isInteger(Number(usuarios)) || Number(usuarios) < 0) {
        toast.error(
          `Ha ocurrido un error al crear la ASADA: "El campo 'Usuarios abastecidos' debe ser un número entero positivo."`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return false;
      }
    }

    if (gradientes) {
      const gradientesAsNumber = parseInt(gradientes, 10);
      if (!Number.isInteger(gradientesAsNumber) || gradientesAsNumber < 0) {
        toast.error(
          `Ha ocurrido un error al crear la ASADA: "El campo 'Quiebragradientes' debe ser un número entero positivo."`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return false;
      }
    }
    if (tanques) {
      const tanquesAsNumber = parseInt(tanques, 10);
      if (!Number.isInteger(tanquesAsNumber) || tanquesAsNumber < 0) {
        toast.error(
          `Ha ocurrido un error al crear la ASADA: "El campo 'Tanques' debe ser un número entero positivo."`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return false;
      }
    }
    if (pozos) {
      const pozosAsNumber = parseInt(pozos, 10);
      if (!Number.isInteger(pozosAsNumber) || pozosAsNumber < 0) {
        toast.error(
          `Ha ocurrido un error al crear la ASADA: "El campo 'Pozos' debe ser un número entero positivo."`,
          {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = {
      nombre: newNombre,
      slogan: newSlogan,
      historia: newHistoria,
      num_empresa_cisa: newNumEmpresaCisa,
      cedulaJuridica: newCedulaJuridica,
      mision: newMission,
      vision: newVision,
      horario: newSchedule,
      extension: newExtension,
      direccion: newAddress,
      usuarios: newUsers,
      tanques: newTanks,
      gradientes: newGradientes,
      pozos: newPozos,
      texto_metodos_pago: newTextoMetodosPago,
      texto_proyectos: newTextoProyectos,
    };

    if (!checkFormValidation(form)) return;

    await updateAsadaAdmin(form, id, restore);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (
      window.confirm(
        "Se eliminará de la base de datos el registro de la ASADA. Esta acción no se puede revertir.\n ¿Seguro que desea continuar?"
      )
    ) {
      await deleteAsadaAdmin(id, forceDelete);
    }
  };

  const handleDeleteChange = () => {
    setForceDelete((prev) => !prev);
  };
  const handleRestoreChange = () => {
    setRestore((prev) => !prev);
  };

  return (
    <div>
      <div className="card-body p-0">
        <h2 className="text-2xl my-4 font-bold card-title">
          <button
            onClick={() => navigate("/admin/asadas")}
            className="btn btn-outline btn-accent btn-sm hover:text-white"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 " viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          Editar Asada
        </h2>

        <div className="flex justify-center text-lg">
          <form className="w-full " onSubmit={handleSubmit}>
            <div className="flex">
              <div className="flex-1 w-1/2">
                <div className="w-full px-3">
                  <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-nombre">
                    Nombre *
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-nombre"
                    type="text"
                    required
                    name="nombre"
                    placeholder="Ingresar nombre de la ASADA"
                    defaultValue={nombre}
                    onChange={(e) => setNewNombre(e.target.value)}
                  />
                </div>
                <div className="w-full px-3">
                  <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-cedulaJuridica">
                    Cédula Jurídica *
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-cedulaJuridica"
                    type="text"
                    required
                    name="cedulaJuridica"
                    placeholder="1-123-123456"
                    defaultValue={cedulaJuridica}
                    onChange={(e) => setNewCedulaJuridica(e.target.value)}
                  />
                </div>

                <div className="w-full px-3">
                  <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-numEmpresaCisa">
                    Número de Empresa CISA *
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-numEmpresaCisa"
                    type="text"
                    required
                    name="numEmpresaCisa"
                    placeholder="Ingresar Número de Empresa CISA"
                    defaultValue={num_empresa_cisa}
                    onChange={(e) => setNewNumEmpresaCisa(e.target.value)}
                  />
                </div>

                <div className="w-full px-3">
                  <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-slogan">
                    Slogan
                  </label>
                  <textarea
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-slogan"
                    type="text"
                    name="slogan"
                    placeholder="Ingresar slogan de la ASADA"
                    defaultValue={slogan}
                    onChange={(e) => setNewSlogan(e.target.value)}
                  />
                </div>

                <div className="w-full px-3">
                  <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-capacity">
                    Misión
                  </label>
                  <textarea
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-capacity"
                    type="text"
                    required
                    name="capacity"
                    placeholder="Ingresar misión de la ASADA"
                    defaultValue={mision}
                    onChange={(e) => setNewMission(e.target.value)}
                  />
                </div>
                <div className="w-full px-3">
                  <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-visión">
                    Visión
                  </label>
                  <textarea
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-visión"
                    type="text"
                    required
                    name="visión"
                    placeholder="Ingresar visión de la ASADA"
                    defaultValue={vision}
                    onChange={(e) => setNewVision(e.target.value)}
                  />
                </div>
                <div className="w-full px-3">
                  <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-historia">
                    Historia
                  </label>
                  <textarea
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-historia"
                    type="text"
                    name="historia"
                    placeholder="Ingresar historia de la ASADA"
                    defaultValue={historia}
                    onChange={(e) => setNewHistoria(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex-1 w-1/2">
                <div className="w-full px-3">
                  <label className="block tracking-wide text-xs font-bold mb-2" htmlFor="grid-textoProyecto">
                    Texto de la sección 'Proyectos'
                  </label>
                  <textarea
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-textoProyecto"
                    type="text"
                    required
                    name="textoProyecto"
                    placeholder="Ingresar texto que aparecerá en la sección Proyectos"
                    defaultValue={texto_proyectos}
                    onChange={(e) => setNewTextoProyectos(e.target.value)}
                  />
                </div>
                <div className="w-full px-3">
                  <label className="block tracking-wide text-xs font-bold mb-2" htmlFor="grid-textoMetodosPago">
                    Texto de la sección 'Métodos de Pago'
                  </label>
                  <textarea
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-textoMetodosPago"
                    type="text"
                    required
                    name="textoMetodosPago"
                    placeholder="Ingresar texto que aparecerá en la sección Métodos de Pago"
                    defaultValue={texto_metodos_pago}
                    onChange={(e) => setNewTextoMetodosPago(e.target.value)}
                  />
                </div>
                <div className="flex flex-wrap w-full">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block  tracking-wide text-xs font-bold mb-2" htmlFor="grid-users">
                      Usuarios abastecidos
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-users"
                      type="text"
                      required
                      name="users"
                      placeholder="# de usuarios abastecidos"
                      defaultValue={usuarios}
                      onChange={(e) => setNewUsers(e.target.value)}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block  tracking-wide  text-xs font-bold mb-2" htmlFor="grid-extension">
                      Extensión
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-extension"
                      type="text"
                      required
                      name="extension"
                      placeholder="Extensión de la ASADA"
                      defaultValue={extension}
                      onChange={(e) => setNewExtension(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap w-full">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block  tracking-wide text-xs font-bold mb-2" htmlFor="grid-users">
                      Quiebragradientes
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-users"
                      type="text"
                      required
                      name="users"
                      placeholder="# de usuarios abastecidos"
                      defaultValue={gradientes}
                      onChange={(e) => setNewGradientes(e.target.value)}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block  tracking-wide  text-xs font-bold mb-2" htmlFor="grid-tanques">
                      Tanques
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-tanques"
                      type="text"
                      required
                      name="tanques"
                      placeholder="# de tanques"
                      defaultValue={tanques}
                      onChange={(e) => setNewTanks(e.target.value)}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block tracking-wide text-xs font-bold mb-2" htmlFor="grid-pozos">
                      Pozos
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-pozos"
                      type="text"
                      required
                      name="pozos"
                      placeholder="# de pozos"
                      defaultValue={pozos}
                      onChange={(e) => setNewPozos(e.target.value)}
                    />
                  </div>
                  <div className="w-full px-3">
                    <label className="block tracking-wide text-xs font-bold mb-2" htmlFor="grid-dirección">
                      Dirección
                    </label>
                    <textarea
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-dirección"
                      type="text"
                      required
                      name="dirección"
                      placeholder="Ingresar dirección de la ASADA"
                      defaultValue={direccion}
                      onChange={(e) => setNewAddress(e.target.value)}
                    />
                  </div>
                  <div className="w-full px-3">
                    <label className="block tracking-wide text-xs font-bold mb-2" htmlFor="grid-horario">
                      Horario
                    </label>
                    <textarea
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-horario"
                      type="text"
                      required
                      name="horario"
                      placeholder="Ingresar horario de la ASADA"
                      defaultValue={horario}
                      onChange={(e) => setNewSchedule(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full px-3 flex gap-2 mt-4">
              <input type="checkbox" id="force" className="h-5 w-5" onChange={handleDeleteChange} />
              <label htmlFor="force" className="cursor-pointer tracking-wide  text-xs font-bold mb-2">
                &nbsp;Borrar permanentemente
              </label>
            </div>
            {deletedAt && (
              <div className="w-full px-3 flex gap-2 mt-4">
                <input type="checkbox" id="restaurar" className="h-5 w-5" onChange={handleRestoreChange} />
                <label htmlFor="restaurar" className="cursor-pointer tracking-wide  text-xs font-bold mb-2">
                  &nbsp;Restaurar
                </label>
              </div>
            )}

            <small className="ml-1 text-2xs text-gray-500">* Campos requeridos</small>
            <div className="divider"></div>

            <div className="card-actions justify-start">
              <button
                className={`btn btn-success hover:bg-green-700 capitalize ${isLoading ? "loading" : ""}`}
                type="submit"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                </svg>
                &nbsp;Guardar cambios
              </button>
              <button className="btn btn-error hover:bg-red-700 capitalize" type="button" onClick={handleDelete}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
                &nbsp;Borrar ASADA
              </button>
              <button type="button" className="btn btn-ghost ml-2" onClick={openModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <div>
          <p className="font-light text-lg">Consideraciones</p>
          <ul className="list-disc">
            <li className="font-light text-sm my-1">Los campos requeridos están marcados con un asterisco (*).</li>
            <li className="font-light text-sm my-1">
              Los campos 'Horario de Atención' y 'Dirección' se muestran en la página pública, en la pestaña 'Contacto'.
              Todos los demás campos se muestran en la pestaña de Inicio.
            </li>
            <li className="font-light text-sm my-1">
              Los campos 'Usuarios abastecidos', 'Tanques de Almacenamiento' y 'Quiebragradientes' deben ser números
              enteros mayores a 0.
            </li>
            <li className="font-light text-sm my-1">
              Para borrar un registro permanentemente de la base de datos marque la casilla 'Borrar permanentemente' y
              luego presione el botón 'Borrar ASADA'. Para hacer un 'soft-delete' desmarque la casilla.
            </li>
            <li className="font-light text-sm my-1">
              Para restaurar un registro que fue 'soft-deleted' marque la casilla 'Restaurar' y luego presione el botón
              'Guardar Cambios'. Esta casilla solamente aparece cuando un registro ha sido 'soft-deleted'.
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default AsadaItem;
