import { useEffect, useState } from "react";
import defaultImg from "../../../assets/defaultImg.png";

function Cards({ asada }) {
  const { extension, usuarios, tanques, gradientes, fotoEstaticas } = asada;

  const [extensionImgFilename, setExtensionImgFilename] = useState("");
  const [usuariosImgFilename, setUsuariosImgFilename] = useState("");
  const [tanqueCardImgFilename, setTanqueCardImgFilename] = useState("");
  const [gradienteCardImgFilename, setGradienteCardImgFilename] = useState("");

  useEffect(() => {
    if (fotoEstaticas !== undefined) {
      // console.log(fotoEstaticas);
      if (fotoEstaticas.length > 0) {
        const extensionImg = fotoEstaticas.filter(
          (item) => item.isExtensionImg === true
        )[0];
        const usuariosImg = fotoEstaticas.filter(
          (item) => item.isUsuariosImg === true
        )[0];
        const tanqueCardImg = fotoEstaticas.filter(
          (item) => item.isTanqueCardImg === true
        )[0];
        const gradienteCardImg = fotoEstaticas.filter(
          (item) => item.isGradienteCardImg === true
        )[0];

        if (extensionImg !== undefined)
          setExtensionImgFilename(extensionImg.path.split("/")[4]);
        if (usuariosImg !== undefined)
          setUsuariosImgFilename(usuariosImg.path.split("/")[4]);
        if (tanqueCardImg !== undefined)
          setTanqueCardImgFilename(tanqueCardImg.path.split("/")[4]);
        if (gradienteCardImg !== undefined)
          setGradienteCardImgFilename(gradienteCardImg.path.split("/")[4]);
      }
    }
  }, [fotoEstaticas]);

  return (
    <>
      <section className='general-info my-12  py-8'>
        <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 place-items-center mt-2 gap-3 mx-1 px-6 h-full'>
          <div className='card md:w-96 h-96 lg:w-full bg-base-100 shadow-xl image-full bg-opacity-90 min-w-xl'>
            <figure>
              <img
                src={
                  extensionImgFilename !== ""
                    ? `${
                        process.env.REACT_APP_BASE_URL
                      }/uploads/${extensionImgFilename.split("uploads/")}`
                    : defaultImg
                }
                alt='Extensión de la ASADA'
              />
            </figure>
            <div className='card-body max-h-min text-center flex justify-center '>
              <div className='stat font-black text-white tracking-wider'>
                <h3 className='stat-title text-xl'>Extensión</h3>
                <p className='stat-title text-xl'>{extension}</p>
              </div>
            </div>
          </div>
          <div className='card md:w-96 h-96 lg:w-full bg-base-100 shadow-xl image-full'>
            <figure>
              <img
                src={
                  usuariosImgFilename !== ""
                    ? `${process.env.REACT_APP_BASE_URL}/uploads/${usuariosImgFilename}`
                    : defaultImg
                }
                alt='Usuarios abastecidos'
              />
            </figure>
            <div className='card-body max-h-min text-center flex justify-center'>
              <div className='stat font-black text-white tracking-wider'>
                <h3 className='stat-title text-xl'>Usuarios Abastecidos</h3>
                <p className='stat-title text-xl'>{usuarios}</p>
              </div>
            </div>
          </div>
          <div className='card md:w-96 h-96 lg:w-full bg-base-100 shadow-xl image-full'>
            <figure>
              <img
                src={
                  tanqueCardImgFilename !== ""
                    ? `${process.env.REACT_APP_BASE_URL}/uploads/${tanqueCardImgFilename}`
                    : defaultImg
                }
                alt='Tanque de almacenamiento'
              />
            </figure>
            <div className='card-body max-h-min text-center flex justify-center'>
              <div className='stat font-black text-white tracking-wider'>
                <h3 className='stat-title text-xl'>
                  Tanques de <br />
                  Almacenamiento
                </h3>
                <div className='stat-title text-xl'>{tanques}</div>
              </div>
            </div>
          </div>
          <div className='card md:w-96 h-96 lg:w-full bg-base-100 shadow-xl image-full'>
            <figure>
              <img
                src={
                  gradienteCardImgFilename !== ""
                    ? `${process.env.REACT_APP_BASE_URL}/uploads/${gradienteCardImgFilename}`
                    : defaultImg
                }
                alt='Quiebragradientes'
              />
            </figure>
            <div className='card-body max-h-min text-center flex justify-center'>
              <div className='stat font-black text-white tracking-wider'>
                <div className='stat-title text-xl'>Quiebragradientes</div>
                <div className='stat-title text-xl'>{gradientes}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cards;
